import React, {Component} from 'react';
import DenunciasForm from "./denuncias-form";
import Modal from "react-responsive-modal";
import moment from "moment";
import {formatoMoneda} from "../../../Utils/renderField/renderReadField";
import ToolbarSimple from "../../../Utils/Toolbar/ToolbarSimple";
import Table from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {BreakLine} from "../../../Utils/tableOptions";
import ProductosModal from "../../../Utils/Globales/productosModal-container"

class DenunciasCrear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            prodSeleccionado: "",
            valido: false,
        }
        ;
    }

    setProductoSeleccionado = (rowOrValue) => {
        const prodSeleccionado = typeof (rowOrValue) === "object" ? rowOrValue.titulo : rowOrValue;
        this.props.setCadenaBusqueda(prodSeleccionado);
        this.setState({
            valido: !!prodSeleccionado,
        }, this.closeModal)

    };

    componentWillMount() {
        this.props.getCuentas();
    };

    openModal = (id, row) => {
        this.props.setToggleModal(true);
    };

    closeModal = () => {
        this.props.setToggleModal(false);
    };

    render() {
        const {
            guardar,
        } = this.props;


        const {
            cargando,
            lista_cuentas,
        } = this.props;

        return (
            <div>
                {this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal} className="test-name">
                        <div className="modal-header">
                            <h5>Seleccionar Producto</h5>
                        </div>
                        <div className="modal-detail p-0">
                            <ProductosModal setProductoSeleccionado={this.setProductoSeleccionado}/>
                        </div>
                        {/*<div className="modal-footer">*/}
                        {/*    <button className="btn btn-danger"> <i className="fa fa-times"/> &nbsp; Cancelar</button>*/}
                        {/*    <button className="btn btn-success"> <i className="fa fa-save"/> &nbsp; Guardar</button>*/}
                        {/*</div>*/}
                    </Modal>
                )}
                <div className="title-view">
                    Datos Nueva Denuncia
                </div>
                <div className="content content-view">
                    <div className="panel panel-default">
                        <div className="panel-body pl-5">
                            <DenunciasForm onSubmit={guardar}
                                           valido={this.state.valido}
                                           setProductoSeleccionado={this.setProductoSeleccionado}
                                           lista_cuentas={lista_cuentas}
                                           openProductos={this.openModal}
                                           itemSeleccionado={this.state.prodSeleccionado}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DenunciasCrear;
