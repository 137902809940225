import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";

const url = 'producto';

const TOGGLE_MODAL = 'TOGGLE_MODAL';
const LOADER_PRODUCTOS = 'LOADER_PRODUCTOS';
const SET_PRODUCTOS_DATA = 'SET_PRODUCTOS_DATA';
const SET_PAGE_PRODUCTOS = 'SET_PAGE_PRODUCTOS';
const SET_BUSCADOR_PRODUCTOS = 'SET_BUSCADOR_PRODUCTOS';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_PRODUCTOS, cargando: true});
    const store = getStore();
    const search = store.productos.buscador;
    let params = {page, search};
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PRODUCTOS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_PRODUCTOS_DATA, data});
            dispatch({type: SET_PAGE_PRODUCTOS, page});
        }
        dispatch({type: LOADER_PRODUCTOS, cargando: false});
    })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PRODUCTOS, buscador: search});
    dispatch(listar(1));
};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});

};


export const actions = {
    listar,
    search,
    setToggleModal,
};
export const reducer = {
    [SET_PAGE_PRODUCTOS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_PRODUCTOS_DATA]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_PRODUCTOS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_BUSCADOR_PRODUCTOS]: (state, {buscador}) => {
        return {...state, buscador}
    },
};

export const initialState = {
    page: 1,
    buscador: '',
    editando: false,
    cargando: false,
    toggleModal: false,
    data: {},
};
export default handleActions(reducer, initialState)
