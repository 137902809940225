import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {push} from 'react-router-redux'
import {initialize as initializeForm} from "redux-form";

const url = 'denuncias';

const SET_CUENTAS = 'SET_CUENTAS';
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const LOADER_DENUNCIAS = 'LOADER_DENUNCIAS';
const SET_PAGE_DENUNCIAS = 'SET_PAGE_DENUNCIAS';
const SET_DENUNCIAS_DATA = 'SET_DENUNCIAS_DATA';
const SET_CADENA_BUSQUEDA = 'SET_CADENA_BUSQUEDA';
const SET_EDICION_DENUNCIA = 'SET_EDICION_DENUNCIA';
const SET_BUSCADOR_DENUNCIAS = 'SET_BUSCADOR_DENUNCIAS';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_DENUNCIAS, cargando: true});
    const store = getStore();
    const search = store.denuncias.buscador;
    let params = {page, search};

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_DENUNCIAS_DATA, data});
            dispatch({type: SET_PAGE_DENUNCIAS, page});
        }
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
    })
};


const destroy = (id) => (dispatch) => {
    dispatch({type: LOADER_DENUNCIAS, cargando: true});
    //api.eliminar(`${url}/${id}`).catch((error) => {
    let params = {id};
    api.post(`${url}/eliminar`, params).catch((error) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
};

const guardar = () => (dispatch, getStore) => {
    const editando = getStore().denuncias.editando;
    if (editando) {
        dispatch(update());
    } else {
        dispatch(create());
    }
};


const create = () => (dispatch, getStore) => {
    const formData = getStore().form.denuncias_form.values;
    const dataPost = _.cloneDeep(formData);
    dataPost.cuenta_id = dataPost.cuenta_id.value;
    dispatch({type: LOADER_DENUNCIAS, cargando: true});
    api.post(`${url}/crear`, dataPost).then((data) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire('Éxito', 'Se ha agregado la denuncia.', 'success')
            .then(() => {
                dispatch(push(`/app/denuncias`));
            })
    }).catch((error) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
        dispatch(listar());
    });
};

const update = () => (dispatch, getStore) => {
    const formData = getStore().form.denuncias_form.values;
    const dataPost = _.cloneDeep(formData);
    dataPost.cuenta_id = dataPost.cuenta_id.value;
    dispatch({type: LOADER_DENUNCIAS, cargando: true});
    api.post(`${url}/editar`, dataPost).then((data) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire('Éxito', 'Se ha actualizado la denuncia.', 'success')
            .then(() => {
                dispatch(push(`/app/denuncias`));
            })
    }).catch((error) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
        dispatch(listar());
    });
};

const editar = (id, row) => (dispatch, getStore) => {
    dispatch(push(`/app/denuncias/nueva`));
    const formDenuncias = {
        id,
        marca:row.marca,
        precio:row.precio,
        cadena_busqueda:row.cadena_busqueda,
        porcentaje_margen_precio:row.porcentaje_margen_precio,
        cuenta_id:{label:row.cuenta.nickname, value:row.cuenta.id}
    };
    dispatch(setEdicion(true));
    dispatch(initializeForm('denuncias_form', formDenuncias));
};

const deleteDenuncia = (id) => (dispatch, getStore) => {
    const dataDelete = {
        id
    };
    dispatch({type: LOADER_DENUNCIAS, cargando: true});
    api.post(`${url}/eliminar`, dataDelete).then((data) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire('Éxito', 'Se ha eliminado la denuncia.', 'success')
            .then(() => {
                dispatch(listar());
            })
    }).catch((error) => {
        dispatch({type: LOADER_DENUNCIAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
    });
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_DENUNCIAS, buscador: search});
    dispatch(listar(1));

};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});
    // if (!estado) {
    //     dispatch({type: SET_PAGE_DETAIL_LOTE, pageDetail: 1})
    // }
};

// El async retorna una promesa
const resetBajasPage = () => async (dispatch, getState) => {
    dispatch({type: SET_PAGE_DENUNCIAS, pageBajas: 1});
};

const setCadenaBusqueda = (cadena_busqueda) => (dispatch, getStore) => {
    const form = getStore().form.denuncias_form;
    form !== undefined && (form.values.cadena_busqueda = cadena_busqueda);
};

const setEdicion = (value=false) => (dispatch, getStore) => {
    dispatch({type: SET_EDICION_DENUNCIA, editando: value});
};

const getCuentas = (search) => (dispatch, getState) => {
    return api.get("cuentas_denuncia/listado_cuentas", {search}).then((data) => {
        if (data) {
            let lista_cuentas = [];
            data.results.forEach((item) => {
                let cuenta = {'label': item.nickname, 'value': item.id};
                lista_cuentas.push(cuenta);
            });
            dispatch({type: SET_CUENTAS, lista_cuentas})
        }
    }).catch(() => {
        return [];
    });
};

export const actions = {
    listar,
    create,
    search,
    editar,
    guardar,
    destroy,
    getCuentas,
    setEdicion,
    setToggleModal,
    resetBajasPage,
    deleteDenuncia,
    setCadenaBusqueda,
};
export const reducer = {
    [SET_PAGE_DENUNCIAS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_DENUNCIAS_DATA]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_DENUNCIAS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_CUENTAS]: (state, {lista_cuentas}) => {
        return {...state, lista_cuentas}
    },
    [SET_BUSCADOR_DENUNCIAS]: (state, {buscador}) => {
        return {...state, buscador}
    },
    [SET_CADENA_BUSQUEDA]: (state, {cadena_busqueda}) => {
        return {...state, cadena_busqueda}
    },
    [SET_EDICION_DENUNCIA]: (state, {editando}) => {
        return {...state, editando}
    },
};

export const initialState = {
    page: 1,
    data: {},
    buscador: '',
    editando:false,
    cargando: false,
    lista_cuentas: [],
    toggleModal: false,
    proveedorValue: null,
    cadena_busqueda: null,
};
export default handleActions(reducer, initialState)
