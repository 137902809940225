import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import {activeFormatter} from '../../Utils/Acciones/Acciones';
import {TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment";
import Modal from "react-responsive-modal";
import Select from "react-select";
import {formatoMoneda} from "../../Utils/renderField/renderReadField";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import DayPicker from "../../Utils/DayPicker";


class Lotes extends Component {

    detalles_del_lote = {};

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            id: undefined,
            mostrarBajas: 'ver',
            loteInfo: undefined,
            tipoModal: 'detalle', //puede ser detalle para que la modal muestre los detalles o baja para las bajas
            detalleInfo: undefined,
            editandoDetalle: false,
            idDetalleVer: undefined,
            stockValueEdit: undefined,
            mostrarEdiciones: 'editar',
            idDetalleEditando: undefined,
        }
        ;
    }

    openModal = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            id,
            loteInfo: row,
            tipoModal: 'detalle',
        });
        this.props.detail(1, id);
    };

    editarDetalle = (id, row) => {
        this.setState({
            idDetalleEditando: id,
            editandoDetalle: true,
            mostrarBajas: 'noMostrar',
            mostrarEdiciones: 'noMostrar',
        });
    };

    closeModal = () => {
        this.props.setToggleModal(false);
        this.props.resetBajasPage();

    };

    cargarList = (tabIndex) => {
        this.setState({
            tabIndex,
        }, () => {
            if (tabIndex !== undefined) {
                switch (tabIndex) {
                    case 0: {
                        this.props.cargar_proveedores();
                        this.props.listarDetalles(this.props.pageDetail);
                    }
                        break;
                    case 1: {
                        this.props.cargar_proveedores();
                        this.props.listar(this.props.page);
                    }
                        break;
                    case 2: {
                        this.props.listBajasProducto(this.props.pageBajas);
                    }
                        break;
                    default:
                        this.props.cargar_proveedores();
                        this.props.listar();
                }

            }
        });
    };

    cargarBajas = (id, row) => {
        this.setState({
            idDetalleVer: id,
            detalleInfo: row,
            tipoModal: 'baja',
        }, () => {
            this.props.resetBajasPage().then(() => {
                this.props.listBajas(this.props.pageBajas, id)
            });
        });
    };

    cargarBajasDetalles = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            loteInfo: undefined,
            idDetalleVer: id,
            detalleInfo: row,
            tipoModal: 'baja',
        }, () => {
            this.props.resetBajasPage().then(() => {
                this.props.listBajas(this.props.pageBajas, id)

            });
        });
    };

    habilitarGuardarDetalle = (id, row) => {
        return (
            <button className="btn btn-success" hidden={this.state.idDetalleEditando !== row.id}
                    onClick={(e) => {
                        e.preventDefault();
                        this.guardarDetalle(id, row)
                    }}>
                <span className="fa fa-save"/> Guardar
            </button>
        )
    };

    guardarDetalle = (id, row) => {
        this.setState({
            mostrarBajas: 'ver',
            editandoDetalle: false,
            mostrarEdiciones: 'editar',
            idDetalleEditando: undefined,
        }, () => this.props.updateDetalle(id, this.state.stockValueEdit));

    };


    backDetalles = () => {
        this.setState({
            tipoModal: 'detalle',
        });

    };

    componentWillMount() {
        this.props.cargar_proveedores();
        this.props.listarDetalles();
    };

    cargarProductos = () => {
        this.props.listarProductos();
    };

    inputStock = (cell, row) => {
        return (
            <input
                min={0}
                value={cell}
                type="number"
                placeholder="Stock"
                className="stock-input"
                disabled={this.state.idDetalleEditando !== row.id}
                onChange={(e) => {
                    this.setState({stockValueEdit: e.currentTarget.value});
                    this.props.updateRow(e.currentTarget.value, row)
                }}
            />
        )
    };

    calcularCosto = (cell, row) => {
        if (cell !== undefined) {
            let costo = cell.detalle_orden.pvp;
            let descuento = costo * (cell.detalle_orden.descuento / 100);
            let costo_final = costo - descuento;
            return formatoMoneda(costo_final, '$MX ');

        }
        {
            return 0
        }
    };

    calcularCostoDetalle = (cell, row) => {
        if (cell !== undefined) {
            let costo = cell.pvp;
            let descuento = costo * (cell.descuento / 100);
            let costo_final = costo - descuento;
            return formatoMoneda(costo_final, '$MX ');

        }
        {
            return 0
        }
    };

    render() {
        const {
            detail,
            listar,
            listBajas,
            listarDetalles,
            cambioProveedor,
            cambioFechaFinal,
            cambioFechaInicio,
            listBajasProducto,
            descargarExcelBajas,
            descargarExcelDetalles,
        } = this.props;


        const {
            page,
            data,
            cargando,
            pageBajas,
            bajaDetail,
            pageDetail,
            detailLote,
            fechaFinal,
            fechaInicial,
            proveedorValue,
            cargandoDetalles,
            estadoExcelBajas,
            lista_proveedores,
            estadoExcelDetalles,
        } = this.props;

        return (
            <div>
                {this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal}>
                        <div className="modal-header modal-detail">
                            <div className="panel-body">
                                {(this.state.tipoModal === 'baja' && this.state.detalleInfo && this.state.loteInfo) &&
                                <button className="btn btn-success mb-3" onClick={this.backDetalles}>
                                    <span className="fa fa-arrow-circle-left"> Detalles del lote</span>
                                </button>
                                }
                                <div className="table-decoration">
                                    <div className="top-title-toolbar">
                                        {this.state.tipoModal === 'detalle' ?
                                            <ToolbarSimple
                                                titulo={`Detalles del lote ${this.state.loteInfo.orden_compra.folio}`}
                                            />
                                            :
                                            <ToolbarSimple
                                                titulo={this.state.detalleInfo ? `${this.state.detalleInfo.producto.titulo} - ${this.state.detalleInfo.detalle_orden.orden_compra.folio}` : `Producto`}
                                            />
                                        }
                                    </div>
                                    {this.state.tipoModal === 'detalle' ?
                                        <Table
                                            onPageChange={page => detail(page, this.state.id)}
                                            data={detailLote}
                                            loading={cargandoDetalles}
                                            page={pageDetail}
                                        >
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataAlign='center'
                                                width={'10%'}
                                                dataField="detalle_orden"
                                                dataFormat={(cell) => (cell.cantidad - cell.cantidad_no_entregada)}
                                                dataSort>Inicial</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                width={'10%'}
                                                dataField="stock"
                                                dataFormat={this.inputStock}
                                                dataSort>Stock</TableHeaderColumn>
                                            <TableHeaderColumn
                                                width={'20%'}
                                                // isKey={true}
                                                tdStyle={BreakLine}
                                                dataField="producto"
                                                dataFormat={(cell) => cell.barcode}
                                                dataSort>Barcode
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                width={'50%'}
                                                tdStyle={BreakLine}
                                                dataField="producto"
                                                dataFormat={(cell) => cell.titulo}
                                                dataSort>Título</TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="id"
                                                isKey={true}
                                                dataAlign="center"
                                                dataFormat={activeFormatter({
                                                    [this.state.mostrarEdiciones]: this.editarDetalle,
                                                    [this.state.mostrarBajas]: this.cargarBajas,
                                                    adicional: this.habilitarGuardarDetalle,
                                                })}>Acciones
                                            </TableHeaderColumn>
                                        </Table>
                                        :
                                        <Table
                                            onPageChange={page => listBajas(page, this.state.id)}
                                            data={bajaDetail}
                                            loading={cargandoDetalles}
                                            page={pageBajas}
                                        >
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataField="creado"
                                                isKey={true}
                                                dataFormat={(cell) => (moment(cell).format("DD/MM/YYYY"))}
                                                dataSort>Fecha</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataField="detalle_orden"
                                                dataFormat={(cell) => cell.orden.numero_pedido}
                                                dataSort>Número Pedido</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataField="cantidad_disminuida"
                                                dataSort>Cantidad</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataField="detalle_lote"
                                                dataFormat={this.calcularCosto}
                                                dataSort>Costo</TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdStyle={BreakLine}
                                                dataField="detalle_orden"
                                                dataFormat={(cell) => formatoMoneda(cell.costo ? cell.costo : cell.orden.costo, '$MX ')}
                                                dataSort>Precio</TableHeaderColumn>
                                        </Table>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className="title-view">
                    Lotes B2B y Fulfillment
                </div>
                <div className="content content-view">
                    <Tabs className="py-4" defaultIndex={this.state.tabIndex}
                          onSelect={this.cargarList}>
                        <TabList>
                            <Tab> Detalles de Lotes </Tab>
                            <Tab> Lotes </Tab>
                            <Tab>Bajas por producto </Tab>
                        </TabList>
                        <TabPanel>
                            <div className="py-4 d-flex justify-content-between">
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Proveedor</label>
                                    <Select
                                        backspaceRemovesValue={false}
                                        options={lista_proveedores}
                                        value={proveedorValue}
                                        isClearable
                                        onChange={cambioProveedor}
                                        placeholder="Seleccione un proveedor"
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Inicial</label>
                                    <DayPicker
                                        onChange={cambioFechaInicio}
                                        value={fechaInicial}
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Final</label>
                                    <DayPicker
                                        onChange={cambioFechaFinal}
                                        value={fechaFinal}
                                    />
                                </div>
                            </div>
                            <div className="pb-4 d-flex justify-content-between">
                                <div className="col-sm-3 mx-0 px-0">
                                    <button className="btn btn-success btn-block"
                                            disabled={!detailLote.count > 0 ||  estadoExcelDetalles}
                                            onClick={descargarExcelDetalles}
                                    >
                                        Descargar excel de Detalles
                                    </button>
                                </div>
                            </div>
                            <div className="m-auto row-fluid w-100">
                                <div className="table-decoration">
                                    <div className="top-title-toolbar">
                                        <ToolbarSimple
                                            conBusqueda
                                            titulo="Listado de lotes"
                                            buscar={this.props.search}
                                            buscador={this.props.buscador}
                                        />
                                    </div>
                                    <Table
                                        onPageChange={listarDetalles}
                                        data={detailLote}
                                        loading={cargandoDetalles}
                                        page={pageDetail}
                                    >
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='center'
                                            width={'10%'}
                                            dataField="detalle_orden"
                                            dataFormat={(cell) => (cell.cantidad - cell.cantidad_no_entregada)}
                                            dataSort>Inicial</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            width={'12%'}
                                            dataField="stock"
                                            dataFormat={this.inputStock}
                                            dataSort>Stock</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'18%'}
                                            // isKey={true}
                                            tdStyle={BreakLine}
                                            dataField="producto"
                                            dataFormat={(cell) => cell.barcode}
                                            dataSort>Barcode
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'45%'}
                                            tdStyle={BreakLine}
                                            dataField="producto"
                                            dataFormat={(cell) => cell.titulo}
                                            dataSort>Título</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'25%'}
                                            tdStyle={BreakLine}
                                            dataField="lote"
                                            dataFormat={(cell) => cell.orden_compra.folio}
                                            dataSort>Lote</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='right'
                                            dataField="detalle_orden"
                                            dataFormat={this.calcularCostoDetalle}
                                            dataSort>Costo</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='right'
                                            dataField="producto"
                                            dataFormat={(cell) => formatoMoneda(cell.precio, '$MX ')}
                                            dataSort>Precio Actual</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey={true}
                                            dataAlign="center"
                                            dataFormat={activeFormatter({
                                                [this.state.mostrarEdiciones]: this.editarDetalle,
                                                [this.state.mostrarBajas]: this.cargarBajasDetalles,
                                                adicional: this.habilitarGuardarDetalle,
                                            })}>Acciones
                                        </TableHeaderColumn>
                                    </Table>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="py-4 d-flex justify-content-between">
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Proveedor</label>
                                    <Select
                                        backspaceRemovesValue={false}
                                        options={lista_proveedores}
                                        value={proveedorValue}
                                        isClearable
                                        onChange={cambioProveedor}
                                        placeholder="Seleccione un proveedor"
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Inicial</label>
                                    <DayPicker
                                        onChange={cambioFechaInicio}
                                        value={fechaInicial}
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Final</label>
                                    <DayPicker
                                        onChange={cambioFechaFinal}
                                        value={fechaFinal}
                                    />
                                </div>
                            </div>
                            <div className="m-auto row-fluid w-100">
                                <div className="table-decoration">
                                    <div className="top-title-toolbar">
                                        <ToolbarSimple
                                            conBusqueda
                                            titulo="Listado de lotes"
                                            buscar={this.props.search}
                                            buscador={this.props.buscador}
                                        />
                                    </div>
                                    <Table
                                        onPageChange={listar}
                                        data={data}
                                        loading={cargando}
                                        page={page}
                                    >
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            width={'10%'}
                                            dataField="creado"
                                            dataFormat={(cell) => (moment(cell).format("DD/MM/YYYY"))}
                                            dataSort>Fecha</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'30%'}
                                            // isKey={true}
                                            tdStyle={BreakLine}
                                            dataField="orden_compra"
                                            dataFormat={(cell) => cell.folio}
                                            dataSort>Folio</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'20%'}
                                            tdStyle={BreakLine}
                                            dataField="detalles_del_lote"
                                            dataFormat={(cell) => cell.length}
                                            dataSort>Detalles en lote</TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey={true}
                                            dataAlign="center"
                                            dataFormat={activeFormatter({
                                                ver: this.openModal,
                                                // eliminar: destroy,
                                            })}>Detalles
                                        </TableHeaderColumn>
                                    </Table>
                                </div>
                            </div>
                        </TabPanel>
                        {/*LISTADO DE BAJAS POR PRODUCTOS*/}
                        <TabPanel>
                            <div className="py-4 d-flex justify-content-between">
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Proveedor</label>
                                    <Select
                                        backspaceRemovesValue={false}
                                        options={lista_proveedores}
                                        value={proveedorValue}
                                        isClearable
                                        onChange={cambioProveedor}
                                        placeholder="Seleccione un proveedor"
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Inicial</label>
                                    <DayPicker
                                        onChange={cambioFechaInicio}
                                        value={fechaInicial}
                                    />
                                </div>
                                <div className="col-sm-3 mx-0 px-0">
                                    <label className="font-weight-bolder text-black">Fecha Final</label>
                                    <DayPicker
                                        onChange={cambioFechaFinal}
                                        value={fechaFinal}
                                    />
                                </div>
                            </div>
                            <div className="pb-4 d-flex justify-content-between">
                                <div className="col-sm-3 mx-0 px-0">
                                    <button className="btn btn-success btn-block"
                                            disabled={!bajaDetail.count > 0 ||  estadoExcelBajas}
                                            onClick={descargarExcelBajas}
                                    >
                                        Descargar excel de Bajas
                                    </button>
                                </div>
                            </div>
                            <div className="m-auto row-fluid w-100">
                                <div className="table-decoration">
                                    <div className="top-title-toolbar">
                                        <ToolbarSimple
                                            conBusqueda
                                            titulo="Listado de bajas por producto"
                                            buscar={this.props.search}
                                            buscador={this.props.buscador}
                                        />
                                    </div>
                                    <Table
                                        onPageChange={page => listBajasProducto(page)}
                                        data={bajaDetail}
                                        loading={cargandoDetalles}
                                        page={pageBajas}
                                    >
                                        <TableHeaderColumn
                                            width={'13%'}
                                            tdStyle={BreakLine}
                                            dataField="creado"
                                            dataFormat={(cell) => (moment(cell).format("DD/MM/YYYY"))}
                                            dataSort>Fecha</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'13%'}
                                            tdStyle={BreakLine}
                                            dataField="detalle_orden"
                                            isKey={true}
                                            dataFormat={(cell) => cell.orden.numero_pedido}
                                            dataSort>Pedido</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'15%'}
                                            tdStyle={BreakLine}
                                            dataField="detalle_lote"
                                            dataFormat={(cell) => cell.producto.barcode}
                                            dataSort>Barcode</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'40%'}
                                            tdStyle={BreakLine}
                                            dataField="detalle_lote"
                                            dataFormat={(cell) => cell.producto.titulo}
                                            dataSort>Titulo</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'9%'}
                                            tdStyle={BreakLine}
                                            dataField="cantidad_disminuida"
                                            // dataFormat={(cell) => cell.orden.numero_pedido}
                                            dataSort>#</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='right'

                                            dataField="detalle_lote"
                                            dataFormat={this.calcularCosto}
                                            dataSort>Costo</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='right'
                                            dataField="detalle_orden"
                                            dataFormat={(cell) => formatoMoneda(cell.costo ? cell.costo : cell.orden.costo, '$MX ')}
                                            dataSort>Precio Vendido</TableHeaderColumn>
                                        <TableHeaderColumn
                                            width={'25%'}
                                            tdStyle={BreakLine}
                                            dataFormat={(cell) => cell.detalle_orden.orden_compra.folio}
                                            dataField="detalle_lote"
                                            dataSort>Lote</TableHeaderColumn>
                                    </Table>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>

                </div>
            </div>
        );
    }
}

export default Lotes;
