import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/predictivos/predictivos';
import PredictivoInventario from './predictivo-inventario';

const ms2p = (state) => {
    return {
        ...state.predictivos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PredictivoInventario);
