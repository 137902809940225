import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import sidebar from './modules/sidebar/sidebar';
import navbar from './modules/navbar/navbar';

// PREETAIL MODULES *--------------------------------------------------------------------------------------------------
import lotes from './modules/lotes/lotes'
import cargos from './modules/cargos/cargos'
import predictivos from './modules/predictivos/predictivos'
import carga_masiva from './modules/carga-masiva/carga-masiva'
import cuentas_denuncia from './modules/denuncias/cuentas/cuentas'
import cuentas_excluidas from './modules/denuncias/cuentas-excluidas/cuentas-excluidas'
import productos_denunciados from './modules/denuncias/productos-denunciados/productos-denunciados'
import denuncias from './modules/denuncias/denuncias/denuncias'
import productos from './modules/productos/productos'

export default combineReducers({
    form: formReducer,
    login,
    register,
    routing,
    sidebar,
    navbar,
    predictivos,
    carga_masiva,
    lotes,
    cargos,
    cuentas_excluidas,
    cuentas_denuncia,
    productos_denunciados,
    denuncias,
    productos

});
