import React from 'react';
import {Field, reduxForm} from "redux-form";
import {renderField, renderFileButtonPicker, renderNumber} from "../../../Utils/renderField";
import {validate, validators} from "validate-redux-form";
import Select from "react-select";
import {renderImagePicker, SelectField} from "../../../Utils/renderField/renderField";


let UploadFileForm = props => {
    const {handleSubmit, cancelForm, archivoAdjunto, setArchivoAdjunto, id, row, idSubiendo, loaded, descargarZip} = props;
    return (
        (!idSubiendo || (id === idSubiendo)) &&
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="">
                    <Field
                        name="archivo_adjunto"
                        photo={archivoAdjunto}
                        setFile={setArchivoAdjunto}
                        id={id}
                        loaded={loaded}
                        component={renderFileButtonPicker}/>
                    {
                        (!idSubiendo && row.total_adjuntos > 0) &&
                        <button className="btn btn-success btn-block" title="Descargar archivos adjuntos" type="button" onClick={descargarZip}><i className="fa fa-file-zip-o"/></button>
                    }
                    {
                        idSubiendo &&
                        <div>
                            <button type="reset" onClick={cancelForm} className="btn btn-danger"><i
                                className="fa fa-times"/>
                            </button>
                            <button type="submit" className="btn btn-success"><i
                                className="fa fa-save"/>
                            </button>
                        </div>
                    }

                </div>
            </div>
        </form>
    );
};
UploadFileForm = reduxForm({
    form: 'upload_file_form',
    validate: data => {
        return validate(data, {})
    }
})(UploadFileForm);

export default UploadFileForm;
