import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";

const url = 'cuentas_denuncia';

const TOGGLE_MODAL = 'TOGGLE_MODAL';
const LOADER_CUENTAS_EXCLUIDAS = 'LOADER_CUENTAS_EXCLUIDAS';
const SET_CUENTAS_EXCLUIDAS_DATA = 'SET_CUENTAS_EXCLUIDAS_DATA';
const SET_PAGE_CUENTAS_EXCLUIDAS = 'SET_PAGE_CUENTAS_EXCLUIDAS';
const SET_BUSCADOR_CUENTAS_EXCLUIDAS = 'SET_BUSCADOR_CUENTAS_EXCLUIDAS';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    const store = getStore();
    const search = store.cuentas_excluidas.buscador;
    const cuentas_excluidas = true;
    let params = {page, search, cuentas_excluidas};
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_CUENTAS_EXCLUIDAS_DATA, data});
            dispatch({type: SET_PAGE_CUENTAS_EXCLUIDAS, page});
        }
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
    })
};

// const createInitialize = () => (dispatch) => {
//     dispatch(initializeForm('patients', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
// };

const create = (formData) => (dispatch, getStore) => {
    // const formData = getStore().form.cuentas_excluidas_form.values;
    let data = {...formData, 'manager_account': false};
    console.log(data);
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    api.post(`${url}`, data).then((data) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Éxito', 'Se ha agregado la cuenta para excluir.', 'success')
            .then(() => {
                dispatch({type: TOGGLE_MODAL, toggleModal: false});
                dispatch(listar());
            })
    }).catch((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false})
    });
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_CUENTAS_EXCLUIDAS, buscador: search});
    dispatch(listar(1));
};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});
    // if (!estado) {
    //     dispatch({type: SET_PAGE_DETAIL_LOTE, pageDetail: 1})
    // }
};

const destroy = (id) => (dispatch) => {
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    //api.eliminar(`${url}/${id}`).catch((error) => {
    api.eliminar(`${url}/${id}`, {'cuentas_excluidas':false}).then((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    }).catch((data) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    })
};

export const actions = {
    listar,
    create,
    destroy,
    search,
    setToggleModal,
};
export const reducer = {
    [SET_PAGE_CUENTAS_EXCLUIDAS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_CUENTAS_EXCLUIDAS_DATA]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_CUENTAS_EXCLUIDAS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_BUSCADOR_CUENTAS_EXCLUIDAS]: (state, {buscador}) => {
        return {...state, buscador}
    },
};

export const initialState = {
    page: 1,
    buscador: '',
    cargando: false,
    toggleModal: false,
    data: {},
};
export default handleActions(reducer, initialState)
