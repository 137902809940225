import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";
import {reset} from 'redux-form';

const url = 'productos_denunciados';

const TOGGLE_MODAL = 'TOGGLE_MODAL';
const SET_ESTADO_EXCEL = 'SET_ESTADO_EXCEL';
const SET_ADJUNTO_PROD_DEN = 'SET_ADJUNTO_PROD_DEN';
const SET_FECHA_FINAL_VALUE = 'SET_FECHA_FINAL_VALUE';
const SET_FECHA_INICIAL_VALUE = 'SET_FECHA_INICIAL_VALUE';
const SET_PRODUCTOS_DENUNCIADOS = 'SET_PRODUCTOS_DENUNCIADOS';
const SET_STATUS_ARCHIVO_ADJUNTO = 'SET_STATUS_ARCHIVO_ADJUNTO';
const LOADER_PRODUCTOS_DENUNCIADOS = 'LOADER_PRODUCTOS_DENUNCIADOS';
const SET_PAGE_PRODUCTOS_DENUNCIADOS = 'SET_PAGE_PRODUCTOS_DENUNCIADOS';
const SET_BUSCADOR_PRODUCTOS_DENUNCIADOS = 'SET_BUSCADOR_PRODUCTOS_DENUNCIADOS';
const SET_ESTADO_ITEM_PRODUCTOS_DENUNCIADOS = 'SET_ESTADO_ITEM_PRODUCTOS_DENUNCIADOS';
const estadosDescarga = {
    PROCESANDO: 10,
    TERMINADO: 20,
    FALLIDO: 30
};

const listado_estados = [
    {'label': 'activos', 'value': 'active'},
    {'label': 'inactivos', 'value': 'inactive'},
    {'label': 'cerrados', 'value': 'closed'},
    {'label': 'pausados', 'value': 'paused'},
    {'label': 'bajo revisión', 'value': 'under_review'},
    {'label': 'esperando activación', 'value': 'not_yet_active'},
    {'label': 'pago de activación requerido', 'value': 'payment_required'},
];

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargando: true});
    const store = getStore();
    const search = store.productos_denunciados.buscador;
    const status_item = store.productos_denunciados.status_item;
    const fecha_inicial = store.productos_denunciados.fechaInicial;
    const fecha_final = store.productos_denunciados.fechaFinal;
    let params = {page, search};
    if (status_item) {
        params['status_item'] = status_item.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/fechas`, params).catch((error) => {
        dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_PRODUCTOS_DENUNCIADOS, data});
            dispatch({type: SET_PAGE_PRODUCTOS_DENUNCIADOS, page});
        }
        dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargando: false});
    })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PRODUCTOS_DENUNCIADOS, buscador: search});
    dispatch(listar(1));
};

const cambioFechaInicio = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_FECHA_INICIAL_VALUE, fechaInicial: param});
    dispatch(listar(1));
};

const cambioFechaFinal = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_FECHA_FINAL_VALUE, fechaFinal: param});
    dispatch(listar(1));

};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});

};

const descargarExcel = () => (dispatch, getStore) => {
    // dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    const store = getStore();
    const search = store.productos_denunciados.buscador;
    const fecha_inicial = store.productos_denunciados.fechaInicial;
    const status_item = store.productos_denunciados.status_item;
    const fecha_final = store.productos_denunciados.fechaFinal;
    let params = {search};

    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    if (status_item) {
        params['status_item'] = status_item.value
    }
    api.get(`${url}/descargar_excel`, params).catch((error) => {
        if (error.statusCode === 404) {
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL, estadoExcel: false})
        }
    }).then((data) => {
        if (data) {
            let nombreArchivo = '';
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL, estadoExcel: true});
            dispatch(esperarDescarga(data.id, nombreArchivo, 'Detalles'))
        }
    })
};

const descargarZip = (id_pd) => (dispatch, getStore) => {
    dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargandoDetalles: true});
    let params = {id_pd};
    api.get(`${url}/zip_archivos`, params).catch((error) => {
        if (error.statusCode === 404) {
            //SWAL
            dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargandoDetalles: false});
        }
    }).then((data) => {
        if (data) {
            window.open(`/api/${url}/zip_archivos?id_pd=${id_pd}`, '_blank')
            // let nombreArchivo = '';
            // //SWAL
            // dispatch({type: SET_ESTADO_EXCEL, estadoExcel: true});
            // dispatch(esperarDescarga(data.id, nombreArchivo, 'Detalles'))
        }
    })
};

const setArchivoAdjunto = (status) => (dispatch, getStore) => {
    (status)
        ?
        dispatch(setStatusArchivoCargado(true))
        :
        dispatch(setStatusArchivoCargado(false))
};

const setStatusArchivoCargado = (status = false) => (dispatch, getStore) => {
    dispatch({
        type: SET_STATUS_ARCHIVO_ADJUNTO,
        archivoCargado: status
    });
};

const guardarArchivo = (datos, adjuntos) => (dispatch) => {
    api.postAttachments(`${url}/subir_archivo`, datos, adjuntos).then((error) => {
        // dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargando: true});
        Swal.fire('Éxito', 'Se ha subido correctamente el archivo.', 'success')
            .then(() => {
                dispatch(listar());
            })
    }).catch((error) => {
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
        dispatch(createInitialize());
        dispatch(setArchivoAdjunto(false));
        // dispatch({type: LOADER_PRODUCTOS_DENUNCIADOS, cargando: false});
    });
};
const esperarDescarga = (idArchivo, nombreArchivo) => (dispatch, getStore) => {
    let intervalPromise;
    intervalPromise = setInterval(() => {
        let params = {id: idArchivo};
        api.get(`${url}/estado_descarga`, params).catch((error) => {
            if (error.statusCode === 404) {
                let msg = 'Ocurrió un error al descargar el archivo. Por favor intenta más tarde';
                if (error.estado) {
                    msg = error.observaciones;
                }
                dispatch({type: SET_ESTADO_EXCEL, estadoExcel: false});
                // $interval.cancel(intervalPromise);
                clearInterval(intervalPromise);
                swal('Error al descargar!', msg, 'error');
            }
        }).then((data) => {
            if (data) {
                if (data.estado === estadosDescarga.PROCESANDO) {
                    dispatch({type: SET_ESTADO_EXCEL, estadoExcel: true});
                } else if (data.estado === estadosDescarga.TERMINADO) {
                    clearInterval(intervalPromise);
                    // $interval.cancel(intervalPromise);
                    if (!nombreArchivo)
                        nombreArchivo = data.archivo ? data.archivo.split('/media/archivos/')[1] : 'Listado_productos_denunciados.xlsx';
                    let context = {
                        name: nombreArchivo,
                        url: data.archivo
                    };
                    dispatch({type: SET_ESTADO_EXCEL, estadoExcel: false});
                    dispatch(descargarArchivo(context))

                }
            }
        })
    }, 1000);
};

const descargarArchivo = (context) => (dispatch, getStore) => {
    window.location.replace(context.url);
    dispatch({type: SET_ESTADO_EXCEL, estadoExcel: false});


};

const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('upload_file_form', {}))
};
const cambioStatus = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_ESTADO_ITEM_PRODUCTOS_DENUNCIADOS, status_item: param});
    dispatch(listar(1));
};

export const actions = {
    listar,
    search,
    descargarZip,
    cambioStatus,
    descargarExcel,
    setToggleModal,
    guardarArchivo,
    cambioFechaFinal,
    cambioFechaInicio,
    setArchivoAdjunto,
};
export const reducer = {
    [SET_PAGE_PRODUCTOS_DENUNCIADOS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_PRODUCTOS_DENUNCIADOS]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_PRODUCTOS_DENUNCIADOS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_BUSCADOR_PRODUCTOS_DENUNCIADOS]: (state, {buscador}) => {
        return {...state, buscador}
    },
    [SET_FECHA_INICIAL_VALUE]: (state, {fechaInicial}) => {
        return {...state, fechaInicial}
    },
    [SET_FECHA_FINAL_VALUE]: (state, {fechaFinal}) => {
        return {...state, fechaFinal}
    },
    [SET_ESTADO_EXCEL]: (state, {estadoExcel}) => {
        return {...state, estadoExcel}
    },
    [SET_ESTADO_ITEM_PRODUCTOS_DENUNCIADOS]: (state, {status_item}) => {
        return {...state, status_item}
    },
    [SET_ADJUNTO_PROD_DEN]: (state, {archivoAdjunto}) => {
        return {...state, archivoAdjunto}
    },
    [SET_STATUS_ARCHIVO_ADJUNTO]: (state, {archivoCargado}) => {
        return {...state, archivoCargado}
    },
};

export const initialState = {
    page: 1,
    data: {},
    buscador: '',
    cargando: false,
    status_item: null,
    toggleModal: false,
    estadoExcel: false,
    archivoAdjunto: null,
    archivoCargado: false,
    listado_estados: listado_estados,
    fechaFinal: moment().format('YYYY-MM-DD'),
    fechaInicial: moment().startOf('month').format('YYYY-MM-DD'),
};
export default handleActions(reducer, initialState);
