import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/denuncias/cuentas/cuentas';
import Cuentas from './cuentas';

const ms2p = (state) => {
    return {
        ...state.cuentas_denuncia,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Cuentas);
