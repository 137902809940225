import React from 'react';
import {Link} from 'react-router-dom';
import {validate, validators} from 'validate-redux-form';
import {Field, reduxForm} from 'redux-form';
import {renderField} from "../../../Utils/renderField/renderField.js";

let CuentasExcluidasForm = props => {
    const {handleSubmit, cancelForm} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group row">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nickname</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nickname" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="" className="m-0">Cliente ID</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="client_id" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end col-md-12">
                    <button type="reset" onClick={cancelForm} className="btn btn-danger mr-2"><i className="fa fa-times"/> &nbsp; Cancelar</button>
                    <button type="submit" className="btn btn-success"><i className="fa fa-save"/> &nbsp; Guardar</button>
                </div>
            </div>
        </form>
    )
};
CuentasExcluidasForm = reduxForm({
    form: 'cuentas_excluidas_form',
    validate: data => {
        return validate(data, {
            // 'nickname': validators.exists()('Campo requerido.'),
            'client_id': validators.exists()('Campo requerido.'),
        })
    }
})(CuentasExcluidasForm);

export default CuentasExcluidasForm
