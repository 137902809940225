import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import SearchChange from "./SearchChange"

export default class ToolbarSimple extends Component {
    render() {
        return (
            <div className="w-100 d-flex justify-content-between">
                <div className={this.props.conBusqueda ? "col-md-6 row" : "col-md-12 row"}>
                    <div className="titulo d-flex align-items-center">
                        {/* <img width={35} className="" src={this.props.icono} alt="Usuario" /> */}
                        <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                    </div>
                </div>
                {
                this.props.conBusqueda &&
                <div className="col-lg-4 col-md-4 text-right search">
                    {(this.props.buscar !== undefined) && (
                        <Search buscar={this.props.buscar} buscador={this.props.buscador}/>
                    )}
                </div>
                }
            </div>
        )
    }
}

