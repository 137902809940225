import {createAction, handleActions} from 'redux-actions';
import _ from "lodash";

import {push} from "react-router-redux";
import {NotificationManager} from "react-notifications";
import {api} from "api";

const SUBMIT = 'SUBMIT';
const LOADER = 'SIDEBAR_LOADER';
const ALERTS = 'ALERTS';


export const constants = {
    ALERTS,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------
export const getAlerts = () => (dispatch) => {
    dispatch(getBitacoraAlerts());
    setInterval(() => {
        dispatch(getBitacoraAlerts());
    }, 15000)
};

export const getBitacoraAlerts = () => (dispatch) => {
    const data = {leido: false, salida: 0};
    api.get('/bitacora/', data).then((response) => {
        dispatch({type: ALERTS, resultAlerts: {alertas: response.results, total_alertas: response.count}})
    }).catch((error) => {
        console.log("error");
        console.log(error);
        // NotificationManager.error('No se ha podido obtener el listado de items', 'ERROR', 0);
    }).finally(() => {
    });
};

export const actions = {
    getAlerts,
    // toggle,
};

export const reducers = {
    [ALERTS]: (state, {resultAlerts}) => {
        return {
            ...state,
            resultAlerts,
        };
    },
};

export const initialState = {
    loader: false,
    resultAlerts: {
        alertas: [],
        total_alertas: 0
    }
};

export default handleActions(reducers, initialState);
