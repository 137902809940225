import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './headersimple.css';

export default class HeaderSimple extends Component {
  render() {
    return (
      <div className="col-md-12 d-flex justify-content-start justify-content-md-start btn-margin">
        {/* <button className="btn btn-primary btn-estadocuenta">
            Agregar usuario
        </button> */}
        <Link className="btn btn-primary" to={this.props.ruta}>
            {this.props.texto}
        </Link>
      </div>
    )
  }
}
