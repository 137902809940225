import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/denuncias/denuncias/denuncias';
import Denuncias from './denuncias';

const ms2p = (state) => {
    return {
        ...state.denuncias,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Denuncias);
