import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import {Collapse} from "reactstrap";
import './SideBar.css';

class Siderbar extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {collapse: false};
    }

    componentDidMount() {
        this.props.getMenuItems();
    }

    toggle() {
        this.setState(state => ({collapse: !state.collapse}));
    }

    render() {
        const {toggleOpen, navToggle, logOut, results, toggle} = this.props;
        const array_varmenus_listos = [
            // 'app/carga_masiva',
            'app/predictivo_inventario',
            'app/lotes',
            'app/cargos',
            'app/cuentas_excluidas',
            'app/cuentas_denuncia',
            'app/productos_denunciados',
            'app/denuncias'
            ]
        ;
        return (
            <aside className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                        <a href="#" className="w-100 mr-0 navbar-brand">
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                     className="d-inline-block align-top mr-1"
                                     src={require('assets/img/logo.png')}
                                     alt="Logo"/>
                            </div>
                        </a>
                        <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                           onClick={navToggle}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <ul className="nav--no-borders flex-column nav">
                        {
                            results.map((menu_item) => {
                                if (menu_item.submenu.length <= 0) {
                                    return (
                                        <li className="nav-item" key={menu_item.id}>
                                            {
                                                (array_varmenus_listos.includes(menu_item.nav_to))
                                                    ?
                                                    (<NavLink to={`/${menu_item.nav_to}`} exact className="nav-link"
                                                              activeClassName={'active'}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={menu_item.icon}/>
                                                        </div>
                                                        <span> {menu_item.text}</span>
                                                    </NavLink>)

                                                    :
                                                    (<a href={`https://app.preetail.com/#/${menu_item.nav_to}`}
                                                        className="nav-link">
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={menu_item.icon}/>
                                                        </div>
                                                        <span> {menu_item.text}</span>
                                                    </a>)
                                            }
                                        </li>
                                    );
                                } else {
                                    return (
                                        <div key={menu_item.id}>
                                            <li className="nav-item">
                                                <a onClick={() => toggle(menu_item.id)} className="nav-link">
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <span className={menu_item.icon}/>
                                                    </div>
                                                    <span> {menu_item.text}</span>
                                                </a>
                                            </li>
                                            <Collapse isOpen={!!menu_item.abierto}>
                                                {
                                                    menu_item.submenu.map((menu_child) => (
                                                            <li className="nav-item" key={menu_child.id}>
                                                                <div className="nav-children">
                                                                    {
                                                                        (array_varmenus_listos.includes(menu_child.nav_to))
                                                                            ?
                                                                            (<NavLink to={`/${menu_child.nav_to}`}
                                                                                      className="nav-link"
                                                                                      activeClassName={'active'}>
                                                                                <div
                                                                                    className="d-inline-block item-icon-wrapper">
                                                                                    <span className={menu_child.icon}/>
                                                                                </div>
                                                                                <span> {menu_child.text}</span>
                                                                            </NavLink>)
                                                                            :
                                                                            (
                                                                                <a href={`https://app.preetail.com/#/${menu_child.nav_to}`}
                                                                                   className="nav-link">
                                                                                    <div
                                                                                        className="d-inline-block item-icon-wrapper">
                                                                                        <span
                                                                                            className={menu_child.icon}/>
                                                                                    </div>
                                                                                    <span> {menu_child.text}</span>
                                                                                </a>)
                                                                    }
                                                                </div>
                                                            </li>
                                                        ),
                                                    )
                                                }
                                            </Collapse>
                                        </div>
                                    );
                                }
                            })
                        }
                        <li className="nav-item">
                            <Link to="/login" onClick={logOut} className="nav-link">
                                <div className="d-inline-block item-icon-wrapper">
                                    <i className="material-icons">lock</i>
                                </div>
                                <span>Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default Siderbar;
