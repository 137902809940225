export const departamentos = [
    {value:'01', label:'Alta Verapaz'},
    {value:'02', label:'Baja Berapaz'},
    {value:'03', label:'Chimaltenango'},
    {value:'04', label:'Chiquimula'},
    {value:'05', label:'El Progreso'},
    {value:'06', label:'Escuintla'},
    {value:'07', label:'Guatemala'},
    {value:'08', label:'Huehuetenango'},
    {value:'09', label:'Izabal'},
    {value:'10', label:'Jalapa'},
    {value:'11', label:'Jutiapa'},
    {value:'12', label:'Petén'},
    {value:'13', label:'Quetzaltenango'},
    {value:'14', label:'Quiché'},
    {value:'15', label:'Retalhuleu'},
    {value:'16', label:'Sacatepéquez'},
    {value:'17', label:'San Marcos'},
    {value:'18', label:'Santa Rosa'},
    {value:'19', label:'Sololá'},
    {value:'20', label:'Suchitepéquez'},
    {value:'21', label:'Totonicapán'},
    {value:'22', label:'Zacapa'}
];

export const municipios = [
    // ALTA VERAPAZ
    {value:'011', label: 'Chahal', departamento:"01"},
    {value:'012', label: 'Chisec', departamento:"01"},
    {value:'013', label: 'Cobán', departamento:"01"},
    {value:'014', label: 'Fray Bartolomé de las Casas', departamento:"01"},
    {value:'015', label: 'La Tinta', departamento:"01"},
    {value:'016', label: 'Lanquín', departamento:"01"},
    {value:'017', label: 'Panzós', departamento:"01"},
    {value:'018', label: 'Raxruhá', departamento:"01"},
    {value:'019', label: 'San Cristóbal Verapaz', departamento:"01"},
    {value:'0110', label: 'San Juan Chamelco', departamento:"01"},
    {value:'0111', label: 'San Pedro Carchá', departamento:"01"},
    {value:'0112', label: 'Santa Cruz Verapaz', departamento:"01"},
    {value:'0113', label: 'Santa María Cahabón', departamento:"01"},
    {value:'0114', label: 'Senahú', departamento:"01"},
    {value:'0115', label: 'Tamahú', departamento:"01"},
    {value:'0116', label: 'Tactic', departamento:"01"},
    {value:'0117', label: 'Tucurú', departamento:"01"},
    // Baja Verapaz
    {value:'021', label: 'Cubulco', departamento:"02"},
    {value:'022', label: 'Granados', departamento:"02"},
    {value:'023', label: 'Purulhá', departamento:"02"},
    {value:'024', label: 'Rabinal', departamento:"02"},
    {value:'025', label: 'Salamá', departamento:"02"},
    {value:'026', label: 'San Jerónimo', departamento:"02"},
    {value:'027', label: 'San Miguel Chicaj', departamento:"02"},
    {value:'028', label: 'Santa Cruz el Chol', departamento:"02"},
    // Chimaltenango
    {value:'031', label: 'Acatenango', departamento:"03"},
    {value:'032', label: 'Chimaltenango', departamento:"03"},
    {value:'033', label: 'El Tejar', departamento:"03"},
    {value:'034', label: 'Parramos', departamento:"03"},
    {value:'035', label: 'Patzicía', departamento:"03"},
    {value:'036', label: 'Patzún', departamento:"03"},
    {value:'037', label: 'Pochuta', departamento:"03"},
    {value:'038', label: 'San Andrés Itzapa', departamento:"03"},
    {value:'039', label: 'San José Poaquil', departamento:"03"},
    {value:'0310', label: 'San Juan Comalpa', departamento:"03"},
    {value:'0311', label: 'San Martín Jilotepeque', departamento:"03"},
    {value:'0312', label: 'Santa Apolonia', departamento:"03"},
    {value:'0313', label: 'Santa Cruz Balanyá', departamento:"03"},
    {value:'0314', label: 'Tecpán', departamento:"03"},
    {value:'0315', label: 'Yepocapa', departamento:"03"},
    {value:'0316', label: 'Zaragoza', departamento:"03"},
    // Chiquimula
    {value:'041', label: 'Camotán', departamento:"04"},
    {value:'042', label: 'Chiquimula', departamento:"04"},
    {value:'043', label: 'Concepción Las Minas', departamento:"04"},
    {value:'044', label: 'Esquipulas', departamento:"04"},
    {value:'045', label: 'Ipala', departamento:"04"},
    {value:'046', label: 'San Juan Ermita', departamento:"04"},
    {value:'047', label: 'Jocotán', departamento:"04"},
    {value:'048', label: 'Olopa', departamento:"04"},
    {value:'049', label: 'Quetzaltepeque', departamento:"04"},
    {value:'0410', label: 'San Jacinto', departamento:"04"},
    {value:'0411', label: 'San José la Arada', departamento:"04"},
    // El Progreso
    {value:'051', label: 'El Jícaro', departamento:"05"},
    {value:'052', label: 'Guastatoya', departamento:"05"},
    {value:'053', label: 'Morazán', departamento:"05"},
    {value:'054', label: 'San Agustín Acasaguastlán', departamento:"05"},
    {value:'058', label: 'San Antonio La Paz', departamento:"05"},
    {value:'056', label: 'San Cristóbal Acasaguastlán', departamento:"05"},
    {value:'057', label: 'Sanarate', departamento:"05"},
    {value:'058', label: 'Sansare', departamento:"05"},
    // Escuintla
    {value:'061', label: 'Escuintla', departamento:"06"},
    {value:'062', label: 'Guanagazapa', departamento:"06"},
    {value:'063', label: 'Iztapa', departamento:"06"},
    {value:'064', label: 'La Democracia', departamento:"06"},
    {value:'065', label: 'La Gomera', departamento:"06"},
    {value:'066', label: 'Masagua', departamento:"06"},
    {value:'067', label: 'Nueva Concepción', departamento:"06"},
    {value:'068', label: 'Palín', departamento:"06"},
    {value:'069', label: 'San José', departamento:"06"},
    {value:'0610', label: 'San Vincente Pacaya', departamento:"06"},
    {value:'0611', label: 'Santa Lucía Cotzumalguapa', departamento:"06"},
    {value:'0612', label: 'Sipacate', departamento:"06"},
    {value:'0613', label: 'Siquinilá', departamento:"06"},
    {value:'0614', label: 'Tiquisate', departamento:"06"},
    // Guatemala
    {value:'071', label: 'Amatitlán', departamento:"07"},
    {value:'072', label: 'Chinautla', departamento:"07"},
    {value:'073', label: 'Chuarrancho', departamento:"07"},
    {value:'074', label: 'Ciudad de Guatemala', departamento:"07"},
    {value:'075', label: 'Frajanes', departamento:"07"},
    {value:'076', label: 'Mixco', departamento:"07"},
    {value:'077', label: 'Palencia', departamento:"07"},
    {value:'078', label: 'San José del Golfo', departamento:"07"},
    {value:'079', label: 'San José Pinula', departamento:"07"},
    {value:'0710', label: 'San Juan Sacatepéquez', departamento:"07"},
    {value:'0711', label: 'San Miguel Petapa', departamento:"07"},
    {value:'0712', label: 'San Pedro Ayampuc', departamento:"07"},
    {value:'0713', label: 'San Pedro Sacatepéquez', departamento:"07"},
    {value:'0714', label: 'San Raymundo', departamento:"07"},
    {value:'0715', label: 'Santa Catarina Pinula', departamento:"07"},
    {value:'0716', label: 'Villa Canales', departamento:"07"},
    {value:'0717', label: 'Villa Nueva', departamento:"07"},
    // Huehuetenango
    {value:'081', label: 'Aguacatán', departamento:"08"},
    {value:'082', label: 'Chiantla', departamento:"08"},
    {value:'083', label: 'Colotenango', departamento:"08"},
    {value:'084', label: 'Concepción Huista', departamento:"08"},
    {value:'085', label: 'Cuilco', departamento:"08"},
    {value:'086', label: 'Huehuetenango', departamento:"08"},
    {value:'087', label: 'Jacaltenango', departamento:"08"},
    {value:'088', label: 'La Democracia', departamento:"08"},
    {value:'089', label: 'La Libertad', departamento:"08"},
    {value:'0810', label: 'Malacatancito', departamento:"08"},
    {value:'0811', label: 'Nentón', departamento:"08"},
    {value:'0812', label: 'Petatán', departamento:"08"},
    {value:'0813', label: 'San Antonio Huista', departamento:"08"},
    {value:'0814', label: 'San Gaspar Ixchil', departamento:"08"},
    {value:'0815', label: 'San Ildefonso Ixtahuacán', departamento:"08"},
    {value:'0816', label: 'San Juan Atitán', departamento:"08"},
    {value:'0817', label: 'San Juan Ixcoy', departamento:"08"},
    {value:'0818', label: 'San Mateo Ixtatán', departamento:"08"},
    {value:'0819', label: 'San Miguel Acatán', departamento:"08"},
    {value:'0820', label: 'San Pedro Nécta', departamento:"08"},
    {value:'0821', label: 'San Pedro Soloma', departamento:"08"},
    {value:'0822', label: 'San Rafael La Independencia', departamento:"08"},
    {value:'0823', label: 'San Rafael Pétzal', departamento:"08"},
    {value:'0824', label: 'San Sebastián Coatán', departamento:"08"},
    {value:'0825', label: 'San Sebastián Huehuetenango', departamento:"08"},
    {value:'0826', label: 'Santa Ana Huista', departamento:"08"},
    {value:'0827', label: 'Santa Bárbara', departamento:"08"},
    {value:'0828', label: 'Santa Cruz Barillas', departamento:"08"},
    {value:'0829', label: 'Santa Eulalia', departamento:"08"},
    {value:'0830', label: 'Santiago Chimaltenango', departamento:"08"},
    {value:'0831', label: 'Tectitán', departamento:"08"},
    {value:'0832', label: 'Todos Santos Cuchumatán', departamento:"08"},
    {value:'0833', label: 'Unión Cantinil', departamento:"08"},
    // Izabal
    {value:'091', label: 'El Estor', departamento:"09"},
    {value:'092', label: 'Livingston', departamento:"09"},
    {value:'093', label: 'Los Amates', departamento:"09"},
    {value:'094', label: 'Morales', departamento:"09"},
    {value:'095', label: 'Puerto Barrios', departamento:"09"},
    // Jalapa
    {value:'101', label: 'Jalapa', departamento:"10"},
    {value:'102', label: 'Mataquescuintla', departamento:"10"},
    {value:'103', label: 'Monjas', departamento:"10"},
    {value:'104', label: 'San Carlos Alzatate', departamento:"10"},
    {value:'105', label: 'San Luis Jilotepeque', departamento:"10"},
    {value:'106', label: 'San Manuel Chaparrón', departamento:"10"},
    {value:'107', label: 'San Pedro Pinula', departamento:"10"},
    // Jutiapa
    {value:'111', label: 'Agua Blanca', departamento:"11"},
    {value:'112', label: 'Asunción Mita', departamento:"11"},
    {value:'113', label: 'Atescatempa', departamento:"11"},
    {value:'114', label: 'Comapa', departamento:"11"},
    {value:'115', label: 'Conguaco', departamento:"11"},
    {value:'116', label: 'El Adelanto', departamento:"11"},
    {value:'117', label: 'El Progreso', departamento:"11"},
    {value:'118', label: 'Jalpatagua', departamento:"11"},
    {value:'119', label: 'Jerez', departamento:"11"},
    {value:'1110', label: 'Jutiapa', departamento:"11"},
    {value:'1111', label: 'Moyuta', departamento:"11"},
    {value:'1112', label: 'Pasaco', departamento:"11"},
    {value:'1113', label: 'Quesada', departamento:"11"},
    {value:'1114', label: 'San José Acatempa', departamento:"11"},
    {value:'1115', label: 'Santa Catarina Mita', departamento:"11"},
    {value:'1116', label: 'Yupiltepeque', departamento:"11"},
    {value:'1117', label: 'Zapotitlán', departamento:"11"},
    // Petén
    {value:'121', label: 'Dolores', departamento:"12"},
    {value:'122', label: 'El Chal', departamento:"12"},
    {value:'123', label: 'Flores', departamento:"12"},
    {value:'124', label: 'La Libertad', departamento:"12"},
    {value:'125', label: 'Las Cruces', departamento:"12"},
    {value:'126', label: 'Melchor de Mencos', departamento:"12"},
    {value:'127', label: 'Poptún', departamento:"12"},
    {value:'128', label: 'San Andrés', departamento:"12"},
    {value:'129', label: 'San Benito', departamento:"12"},
    {value:'1210', label: 'San Francisco', departamento:"12"},
    {value:'1211', label: 'San José', departamento:"12"},
    {value:'1212', label: 'San Luis', departamento:"12"},
    {value:'1213', label: 'Santa Ana', departamento:"12"},
    {value:'1214', label: 'Sayaxché', departamento:"12"},
    // Quetzaltenango
    {value:'131', label: 'Almolonga', departamento:"13"},
    {value:'132', label: 'Cabricán', departamento:"13"},
    {value:'133', label: 'Cajolá', departamento:"13"},
    {value:'134', label: 'Cantel', departamento:"13"},
    {value:'135', label: 'Coatepeque', departamento:"13"},
    {value:'136', label: 'Colomba Costa Cuca', departamento:"13"},
    {value:'137', label: 'Concepción Chiquirichapa', departamento:"13"},
    {value:'138', label: 'El Palmar', departamento:"13"},
    {value:'139', label: 'Flores Costa Cuca', departamento:"13"},
    {value:'1310', label: 'Génova', departamento:"13"},
    {value:'1311', label: 'Huitán', departamento:"13"},
    {value:'1312', label: 'La Esperanza', departamento:"13"},
    {value:'1313', label: 'Olintepeque', departamento:"13"},
    {value:'1314', label: 'Palestina de Los Altos', departamento:"13"},
    {value:'1315', label: 'Quetzaltenango', departamento:"13"},
    {value:'1316', label: 'Salcajá', departamento:"13"},
    {value:'1317', label: 'San Carlos Sija', departamento:"13"},
    {value:'1318', label: 'San Francisco La Unión', departamento:"13"},
    {value:'1319', label: 'San Juan Ostuncalco', departamento:"13"},
    {value:'1320', label: 'San Martín Sacatepéquez', departamento:"13"},
    {value:'1321', label: 'San Mateo', departamento:"13"},
    {value:'1322', label: 'San Miguel Sigüilá', departamento:"13"},
    {value:'1323', label: 'Sibilia', departamento:"13"},
    {value:'1324', label: 'Zunil', departamento:"13"},
    // Quiché
    {value:'141', label: 'Canillá', departamento:"14"},
    {value:'142', label: 'Chajul', departamento:"14"},
    {value:'143', label: 'Chicamán', departamento:"14"},
    {value:'144', label: 'Chiché', departamento:"14"},
    {value:'145', label: 'Santo Tomás Chichicastenango', departamento:"14"},
    {value:'146', label: 'Chinique', departamento:"14"},
    {value:'147', label: 'Cunén', departamento:"14"},
    {value:'148', label: 'Ixcán', departamento:"14"},
    {value:'149', label: 'Joyabaj', departamento:"14"},
    {value:'1410', label: 'Nebaj', departamento:"14"},
    {value:'1411', label: 'Pachalum', departamento:"14"},
    {value:'1412', label: 'Patzité', departamento:"14"},
    {value:'1413', label: 'Sacapulas', departamento:"14"},
    {value:'1414', label: 'San Andrés Sajcabajá', departamento:"14"},
    {value:'1415', label: 'San Antonio Ilotenango', departamento:"14"},
    {value:'1416', label: 'San Bartolomé Jocotenango', departamento:"14"},
    {value:'1417', label: 'San Juan Cotzal', departamento:"14"},
    {value:'1418', label: 'San Pedro Jocopilas', departamento:"14"},
    {value:'1419', label: 'Santa Cruz del Quiché', departamento:"14"},
    {value:'1420', label: 'Uspantán', departamento:"14"},
    {value:'1421', label: 'Zacualpa', departamento:"14"},
    // Retalhuleu
    {value:'151', label: 'Champerico', departamento:"15"},
    {value:'152', label: 'El Asintal', departamento:"15"},
    {value:'153', label: 'Nuevo San Carlos', departamento:"15"},
    {value:'154', label: 'Retalhuleu', departamento:"15"},
    {value:'155', label: 'San Andrés Villa Seca', departamento:"15"},
    {value:'156', label: 'San Felipe', departamento:"15"},
    {value:'157', label: 'San Martín Zapotitlán', departamento:"15"},
    {value:'158', label: 'San Sebastián', departamento:"15"},
    {value:'159', label: 'Santa Cruz Muluá', departamento:"15"},
    // Sacatepéquez
    {value:'161', label: 'Alotenango', departamento:"16"},
    {value:'162', label: 'Ciudad Vieja', departamento:"16"},
    {value:'163', label: 'Jocotenango', departamento:"16"},
    {value:'164', label: 'Antigua Guatemala', departamento:"16"},
    {value:'165', label: 'Magdalena Milpas Altas', departamento:"16"},
    {value:'166', label: 'Pastores', departamento:"16"},
    {value:'167', label: 'San Antonio Aguas Calientes', departamento:"16"},
    {value:'168', label: 'San Bartolomé Milpas Altas', departamento:"16"},
    {value:'169', label: 'San Lucas Sacatepéquez', departamento:"16"},
    {value:'1610', label: 'San Miguel Dueñas', departamento:"16"},
    {value:'1611', label: 'Santa Catarina Barahona', departamento:"16"},
    {value:'1612', label: 'Santa Lucía Milpas Altas', departamento:"16"},
    {value:'1613', label: 'Santa María de Jesús', departamento:"16"},
    {value:'1614', label: 'Santiago Sacatepéquez', departamento:"16"},
    {value:'1615', label: 'Santo Domingo Xenacoj', departamento:"16"},
    {value:'1616', label: 'Sumpango', departamento:"16"},
    // San Marcos
    {value:'171', label:'Ayutla', departamento:"17"},
    {value:'172', label:'Catarina', departamento:"17"},
    {value:'173', label:'Comitancillo', departamento:"17"},
    {value:'174', label:'Concepción Tutuapa', departamento:"17"},
    {value:'175', label:'El Quetzal', departamento:"17"},
    {value:'176', label:'El Tumbador', departamento:"17"},
    {value:'177', label:'Esquipulas Palo Gordo', departamento:"17"},
    {value:'178', label:'Ixchiguán', departamento:"17"},
    {value:'179', label:'La Blanca', departamento:"17"},
    {value:'1710', label:'La Reforma', departamento:"17"},
    {value:'1711', label:'Malacatán', departamento:"17"},
    {value:'1712', label:'Nuevo Progreso', departamento:"17"},
    {value:'1713', label:'Ocós', departamento:"17"},
    {value:'1714', label:'Pajapita', departamento:"17"},
    {value:'1715', label:'Río Blanco', departamento:"17"},
    {value:'1716', label:'San Antonio Sacatepéquez', departamento:"17"},
    {value:'1717', label:'San Cristóbal Cucho', departamento:"17"},
    {value:'1718', label:'San José El Rodeo', departamento:"17"},
    {value:'1719', label:'San José Ojetenam', departamento:"17"},
    {value:'1720', label:'San Lorenzo', departamento:"17"},
    {value:'1721', label:'San Marcos', departamento:"17"},
    {value:'1722', label:'San Miguel Ixtahuacán', departamento:"17"},
    {value:'1723', label:'San Pablo', departamento:"17"},
    {value:'1724', label:'San Pedro Sacatepéquez', departamento:"17"},
    {value:'1725', label:'San Rafael Pie de la Cuesta', departamento:"17"},
    {value:'1726', label:'Sibinal', departamento:"17"},
    {value:'1727', label:'Sipacapa', departamento:"17"},
    {value:'1728', label:'Tacaná', departamento:"17"},
    {value:'1729', label:'Tajumulco', departamento:"17"},
    {value:'1730', label:'Tejutla', departamento:"17"},
    // Santa Rosa
    {value:'181', label: 'Barberena', departamento:"18"},
    {value:'182', label: 'Casillas', departamento:"18"},
    {value:'183', label: 'Chiquimulilla', departamento:"18"},
    {value:'184', label: 'Cuilapa', departamento:"18"},
    {value:'185', label: 'Guazacapán', departamento:"18"},
    {value:'186', label: 'Nueva Santa Rosa', departamento:"18"},
    {value:'187', label: 'Oratorio', departamento:"18"},
    {value:'188', label: 'Pueblo Nuevo Viñas', departamento:"18"},
    {value:'189', label: 'San Juan Tecuaco', departamento:"18"},
    {value:'1810', label: 'San Rafael las Flores', departamento:"18"},
    {value:'1811', label: 'Santa Cruz Naranjo', departamento:"18"},
    {value:'1812', label: 'Santa María Ixhuatán', departamento:"18"},
    {value:'1813', label: 'Santa Rosa de Lima', departamento:"18"},
    {value:'1814', label: 'Taxisco', departamento:"18"},
    // Sololá
    {value:'191', label: 'Concepción', departamento:"19"},
    {value:'192', label: 'Nahualá', departamento:"19"},
    {value:'193', label: 'Panajachel', departamento:"19"},
    {value:'194', label: 'San Andrés Semetabaj', departamento:"19"},
    {value:'195', label: 'San Antonio Palopó', departamento:"19"},
    {value:'196', label: 'San José Chacayá', departamento:"19"},
    {value:'197', label: 'San Juan La Laguna', departamento:"19"},
    {value:'198', label: 'San Lucas Tolimán', departamento:"19"},
    {value:'199', label: 'San Marcos La Laguna', departamento:"19"},
    {value:'1910', label: 'San Pablo La Laguna', departamento:"19"},
    {value:'1911', label: 'San Pedro La Laguna', departamento:"19"},
    {value:'1912', label: 'Santa Catarina Ixtahuacán', departamento:"19"},
    {value:'1913', label: 'Santa Catarina Palopó', departamento:"19"},
    {value:'1914', label: 'Santa Clara La Laguna', departamento:"19"},
    {value:'1915', label: 'Santa Cruz La Laguna', departamento:"19"},
    {value:'1916', label: 'Santa Lucía Utatlán', departamento:"19"},
    {value:'1917', label: 'Santa María Visitación', departamento:"19"},
    {value:'1918', label: 'Santiago Atitlán', departamento:"19"},
    {value:'1919', label: 'Sololá', departamento:"19"},
    // Suchitepéquez
    {value:'201', label: 'Chicacao', departamento:"20"},
    {value:'202', label: 'Cuyotenango', departamento:"20"},
    {value:'203', label: 'Mazatenango', departamento:"20"},
    {value:'204', label: 'Patulul', departamento:"20"},
    {value:'205', label: 'Pueblo Nuevo', departamento:"20"},
    {value:'206', label: 'Río Bravo', departamento:"20"},
    {value:'207', label: 'Samayac', departamento:"20"},
    {value:'208', label: 'San Antonio Suchitepéquez', departamento:"20"},
    {value:'209', label: 'San Bernardino', departamento:"20"},
    {value:'2010', label: 'San Francisco Zapotitlán', departamento:"20"},
    {value:'2011', label: 'San Gabriel', departamento:"20"},
    {value:'2012', label: 'San José El valueolo', departamento:"20"},
    {value:'2013', label: 'San José La Máquina', departamento:"20"},
    {value:'2014', label: 'San Juan Bautista', departamento:"20"},
    {value:'2015', label: 'San Lorenzo', departamento:"20"},
    {value:'2016', label: 'San Miguel Panán', departamento:"20"},
    {value:'2017', label: 'San Pablo Jocopilas', departamento:"20"},
    {value:'2018', label: 'Santa Bárbara', departamento:"20"},
    {value:'2019', label: 'Santo Domingo Suchitepéquez', departamento:"20"},
    {value:'2020', label: 'Santo Tomás La Unión', departamento:"20"},
    {value:'2021', label: 'Zunilito', departamento:"20"},
    // Totonicapán
    {value:'211', label: 'Momostenango', departamento:"21"},
    {value:'212', label: 'San Andrés Xecul', departamento:"21"},
    {value:'213', label: 'San Bartolo', departamento:"21"},
    {value:'214', label: 'San Cristóbal Totonicapán', departamento:"21"},
    {value:'215', label: 'San Francisco El Alto', departamento:"21"},
    {value:'216', label: 'Santa Lucía La Reforma', departamento:"21"},
    {value:'217', label: 'Santa María Chiquimula', departamento:"21"},
    {value:'218', label: 'Totonicapán', departamento:"21"},
    // Zacapa
    {value:'221',label: 'Cabañas', departamento:"22"},
    {value:'222',label: 'Estanzuela', departamento:"22"},
    {value:'223',label: 'Gualán', departamento:"22"},
    {value:'224',label: 'Huité', departamento:"22"},
    {value:'225',label: 'La Unión', departamento:"22"},
    {value:'226',label: 'Río Hondo', departamento:"22"},
    {value:'227',label: 'San Diego', departamento:"22"},
    {value:'228',label: 'San Jorge', departamento:"22"},
    {value:'229',label: 'Teculután', departamento:"22"},
    {value:'2210',label: 'Usumatlán', departamento:"22"},
    {value:'2211',label: 'Zacapa', departamento:"22"}
];
