import Table from '../../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import { activeFormatter } from '../../Utils/Acciones/Acciones';
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";

class CargaMasiva extends Component {

    componentWillMount() {
        this.props.listar();
    }


    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div>
                <div className="title-view">
                    Carga Masiva de Productos
                </div>
                <div className="content content-view">
                    <div className="m-auto row-fluid w-100">
                        <HeaderSimple
                            texto={"Crear paciente"}
                            ruta={"/patients/create"}
                        />
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    titulo="Listado"
                                    // buscar={this.props.search}
                                    // buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    width={'10%'}
                                    dataField="fecha"
                                    dataFormat={(cell) =>(moment(cell).format("DD/MM/YYYY"))}
                                    dataSort>Fecha</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'30%'}
                                    // isKey={true}
                                    tdStyle={BreakLine}
                                    dataField="titulo" dataSort>Titulo</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'20%'}
                                    tdStyle={BreakLine}
                                    dataField="total_productos" dataSort>Productos</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'20%'}
                                    tdStyle={BreakLine}
                                    dataFormat={(cell) => cell.nombre_proveedor}
                                    dataField="proveedor" dataSort>Proveedor</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={activeFormatter({
                                        ver_carga_masiva: '/app/carga_masiva',
                                        eliminar: destroy,
                                    })}>Acciones
                                </TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CargaMasiva;
