import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/denuncias/cuentas-excluidas/cuentas-excluidas';
import CuentasExcluidas from './cuentas-excluidas';

const ms2p = (state) => {
    return {
        ...state.cuentas_excluidas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CuentasExcluidas);
