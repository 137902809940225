import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './acciones.css';
import Swal from 'sweetalert2';

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            url: '',
        };
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }

    redirect(url) {
        return () => {
            this.setState({ url });
            this.setState({ redirect: true });
        };
    }

    eliminar(id) {
        return () => {
            Swal.fire({
                title: '¿Desea eliminar este elemento?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
            })
                .then((result) => {
                    if (result.value) {
                        this.props.eliminar(id);
                    }
                });
        };
    }

    editar(id, row) {
        return () => {
            this.props.editar(id, row);
        };
    }

    ver(id, row) {
        return () => {
            this.props.ver(id, row);
        };
    }

    render() {
        const { id, ver, ver_carga_masiva, editar, eliminar, adicional, obj, row, ver_icono } = this.props;
        if (this.state.redirect) {
            return (<Redirect to={`${this.state.url}/${id}`}/>);
        }
        let offset = 0;
        if (ver !== undefined) {
            offset += 1;
        }
        if (editar !== undefined) {
            offset += 1;
        }
        if (eliminar !== undefined) {
            offset += 1;
        }
        if (ver_icono !== undefined) {
            offset += 1;
        }
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {(adicional !== undefined) && adicional(id, row)}
                    {(ver_icono !== undefined) && (
                        <Link to={`${ver_icono}/${id}/`}>
                            <button className="btn btn-success">
                                <span className="fa fa-eye"/></button>
                        </Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) === "string") && (
                        <Link to={`${editar}/${id}/edit`}>
                            <button className="btn btn-warning">
                                <span className="fa fa-edit"/>
                            </button>
                        </Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) !== "string") && (
                        <button className="btn btn-warning" onClick={this.editar(id, row)}>
                            <span className="fa fa-edit"/>
                        </button>
                    )}
                    {(ver !== undefined) && ((typeof ver) === "string") && (
                        <Link to={`${ver}/${id}/view`}>
                            <button className="btn btn-success" title="Ver">
                                <span className="fa fa-eye"/></button>
                        </Link>
                    )}
                    {(ver_carga_masiva !== undefined) && ((typeof ver_carga_masiva) === "string") && (
                        <Link to={`${ver_carga_masiva}/${id}/${row.fecha}/${row.titulo}/${row.proveedor.nombre_proveedor}/ver_listado`}>
                            <button className="btn btn-success" title="Ver">
                                <span className="fa fa-eye"/></button>
                        </Link>
                    )}
                    {(ver !== undefined) && ((typeof ver) !== "string") && (
                        <button className="btn btn-success" onClick={this.ver(id, row)}>
                            <span className="fa fa-eye"/>
                        </button>
                    )}
                    {(eliminar !== undefined) && (
                        <button className="btn btn-danger" onClick={this.eliminar(id)}>
                            <span className="fa fa-trash"/>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

Acciones.propTypes = {};

export function activeFormatter(acciones) {
    return (cell, row) => {
        return (<Acciones id={cell} {...acciones} row={row} contenido={row}/>);
    };
}

//

export function activeFormatter2(acciones) {
    return (<Acciones id={acciones.cell} {...acciones} row={acciones.row} contenido={acciones.row}/>);
}
