import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";

const url = 'cuentas_denuncia';

const TOGGLE_MODAL = 'TOGGLE_MODAL';
const LOADER_CUENTAS_EXCLUIDAS = 'LOADER_CUENTAS_EXCLUIDAS';
const SET_CUENTAS_EXCLUIDAS_DATA = 'SET_CUENTAS_EXCLUIDAS_DATA';
const SET_PAGE_CUENTAS_EXCLUIDAS = 'SET_PAGE_CUENTAS_EXCLUIDAS';
const SET_EDICION_CUENTA = 'SET_EDICION_CUENTA';
const SET_BUSCADOR_CUENTAS_EXCLUIDAS = 'SET_BUSCADOR_CUENTAS_EXCLUIDAS';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    const store = getStore();
    const search = store.cuentas_excluidas.buscador;
    let params = {page, search};
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_CUENTAS_EXCLUIDAS_DATA, data});
            dispatch({type: SET_PAGE_CUENTAS_EXCLUIDAS, page});
        }
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
    })
};

// const createInitialize = () => (dispatch) => {
//     dispatch(initializeForm('patients', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
// };

const create = (formData) => (dispatch, getStore) => {
    // const formData = getStore().form.cuentas_excluidas_form.values;
    let data = {...formData};
    console.log(data);
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    api.post(`${url}/crear`, data).then((data) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Éxito', 'Se ha agregado la cuenta para excluir.', 'success')
            .then(() => {
                dispatch(setToggleModal(false));
                dispatch(listar());
            })
    }).catch((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        ).then(() => {
        });
    }).finally(() => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false})
    });
};

const editar = id => (dispatch) => {
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    dispatch({type: SET_EDICION_CUENTA, editando: true});
    api.get(`${url}/${id}`).then((response) => {
        // dispatch({type: SET_CUENTAS_EXCLUIDAS_DATA, data:response});
        dispatch(initializeForm('cuentas_excluidas_form', response));
        dispatch({type: TOGGLE_MODAL, toggleModal: true});
    }).catch(() => {
    }).finally(() => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
    });
};

const guardar = (formData) => (dispatch, getStore) => {
    const editando = getStore().cuentas_denuncia.editando;
    let data = {...formData};
    if (editando) {
        dispatch(update(formData));
    } else {
        dispatch(create(formData));
    }
};
const update = (formData) => (dispatch, getStore) => {
    let data = {...formData};
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    api.put(`${url}/${formData.id}`, data)
        .then((data) => {
            dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
            if (data) {
                Swal.fire(
                    'Éxito',
                    'Datos editados correctamente.',
                    'success'
                ).then(() => {
                    dispatch(setToggleModal(false));
                    dispatch(listar());
                })
            }
        })
        .catch((error) => {
            dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            );
            if (error.statusCode === 404) {
                dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false})
            }
        })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_CUENTAS_EXCLUIDAS, buscador: search});
    dispatch(listar(1));
};

const setToggleModal = (estado) => (dispatch, getState) => {
    !estado && dispatch({type: SET_EDICION_CUENTA, editando: false});
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});

};

const destroy = (id) => (dispatch) => {
    dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: true});
    //api.eliminar(`${url}/${id}`).catch((error) => {
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_CUENTAS_EXCLUIDAS, cargando: false});
        Swal.fire('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
};

export const actions = {
    listar,
    create,
    editar,
    update,
    guardar,
    destroy,
    search,
    setToggleModal,
};
export const reducer = {
    [SET_PAGE_CUENTAS_EXCLUIDAS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_CUENTAS_EXCLUIDAS_DATA]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_CUENTAS_EXCLUIDAS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_BUSCADOR_CUENTAS_EXCLUIDAS]: (state, {buscador}) => {
        return {...state, buscador}
    },
    [SET_EDICION_CUENTA]: (state, {editando}) => {
        return {...state, editando}
    },
};

export const initialState = {
    page: 1,
    buscador: '',
    editando: false,
    cargando: false,
    toggleModal: false,
    data: {},
};
export default handleActions(reducer, initialState)
