import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import {activeFormatter} from '../../Utils/Acciones/Acciones';
import {TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment";
import Modal from "react-responsive-modal";
import {formatoMoneda} from "../../Utils/renderField/renderReadField";
import Select from "react-select";
import DayPicker from "../../Utils/DayPicker";


class Cargos extends Component {


    constructor(props) {
        super(props);
        this.state = {
            idReporte: undefined,
            reporteInfo: undefined,
        }
        ;
    }

    openModal = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            id,
            loteInfo: row,
            tipoModal: 'detalle',
        });
        this.props.detail(1, id);
    };

    editarDetalle = (id, row) => {
        this.setState({
            idDetalleEditando: id,
            editandoDetalle: true,
            mostrarBajas: 'noMostrar',
            mostrarEdiciones: 'noMostrar',
        });
    };

    closeModal = () => {
        this.props.setToggleModal(false);
        this.props.resetDetallesPage()

    };

    cargarDetallesReporte = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            idReporte: id,
            reporteInfo: row,
        }, () => this.props.detail(this.props.pageDetalles, id));
    };

    componentWillMount() {
        this.props.listar();
    };

    establecerPeriodo = (cell, row) => {
        if (cell !== undefined) {
            let fecha_inicial = moment(row.fecha_inicial).format("DD/MM/YYYY");
            let fecha_final = moment(row.fecha_final).format("DD/MM/YYYY");
            return `${fecha_inicial} al ${fecha_final}`;

        }
        {
            return 'UNDEFINED'
        }
    };

    setTipoDetalle = (cell, row) => {
        let tipo = '';
        if (cell !== undefined) {
            cell === 10 ? tipo = 'Bonus' : tipo = 'Recargo'
        }
        return tipo
    };

    render() {
        const {
            detail,
            listar,
        } = this.props;


        const {
            page,
            buscador,
            pageDetalles,
            cargando,
            cargandoDetalles,
            dataDetalles,
            toggleModal,
            estadoExcelDetalles,
            data,
        } = this.props;

        return (
            <div>
                {this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal}>
                        <div className="modal-header modal-detail">
                            <div className="panel-body">
                                <div className="d-flex justify-content-between">
                                    <div className="col-sm-3 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">No. Reporte:</h5>
                                        <h5 className="label-info-cm text-capitalize">{`#${this.state.reporteInfo.date_number}`}</h5>
                                    </div>
                                    <div className="col-sm-5 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">Periodo:</h5>
                                        <h5 className="label-info-cm text-capitalize">
                                            {`${moment(this.state.reporteInfo.fecha_inicial).format("DD/MM/YYYY")} 
                                            al 
                                            ${moment(this.state.reporteInfo.fecha_final).format("DD/MM/YYYY")}`}
                                        </h5>
                                    </div>
                                    <div className="col-sm-3 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">Vencimiento:</h5>
                                        <h5 className="label-info-cm text-capitalize">{moment(this.state.reporteInfo.fecha_expiracion).format("DD/MM/YYYY")}</h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="col-sm-3 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">Monto:</h5>
                                        <h5 className="label-info-cm text-capitalize">{formatoMoneda(this.state.reporteInfo.monto, '$MX ')}</h5>
                                    </div>
                                    <div className="col-sm-3 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">Crédito:</h5>
                                        <h5 className="label-info-cm text-capitalize">{formatoMoneda(this.state.reporteInfo.nota_credito, '$MX ')}</h5>
                                    </div>
                                    <div className="col-sm-3 mx-0 px-0">
                                        <h5 className="font-weight-bolder text-black">Impuestos:</h5>
                                        <h5 className="label-info-cm text-capitalize">{formatoMoneda(this.state.reporteInfo.impuesto, '$MX ')}</h5>
                                    </div>
                                </div>
                                <div className="table-decoration">
                                    <div className="top-title-toolbar">
                                        <ToolbarSimple
                                            titulo={`Detalles de reporte`}
                                        />
                                    </div>
                                    <Table
                                        onPageChange={page => detail(page, this.state.idReporte)}
                                        data={dataDetalles}
                                        loading={cargandoDetalles}
                                        page={pageDetalles}
                                    >
                                        <TableHeaderColumn
                                            isKey={true}
                                            tdStyle={BreakLine}
                                            dataField="tipo"
                                            dataAlign="center"
                                            dataFormat={this.setTipoDetalle}
                                            dataSort>Tipo de Detalle</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataField="asunto"
                                            dataSort>Asunto</TableHeaderColumn>
                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            dataAlign='right'
                                            dataField="monto"
                                            dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                            dataSort>Monto</TableHeaderColumn>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className="title-view">
                    Reportes de Facturación (Cargos) Mercado Libre
                </div>
                <div className="content content-view">
                    {/*<div className="py-4 d-flex justify-content-between">*/}
                    {/*    <div className="col-sm-3 mx-0 px-0">*/}
                    {/*        <label className="font-weight-bolder text-black">Proveedor</label>*/}
                    {/*        <Select*/}
                    {/*            backspaceRemovesValue={false}*/}
                    {/*            options={lista_proveedores}*/}
                    {/*            value={proveedorValue}*/}
                    {/*            isClearable*/}
                    {/*            onChange={cambioProveedor}*/}
                    {/*            placeholder="Seleccione un proveedor"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-3 mx-0 px-0">*/}
                    {/*        <label className="font-weight-bolder text-black">Fecha Inicial</label>*/}
                    {/*        <DayPicker*/}
                    {/*            onChange={cambioFechaInicio}*/}
                    {/*            value={fechaInicial}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-3 mx-0 px-0">*/}
                    {/*        <label className="font-weight-bolder text-black">Fecha Final</label>*/}
                    {/*        <DayPicker*/}
                    {/*            onChange={cambioFechaFinal}*/}
                    {/*            value={fechaFinal}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="py-4 d-flex justify-content-between">*/}
                    {/*    <div className="col-sm-3 mx-0 px-0">*/}
                    {/*        <button className="btn btn-success btn-block"*/}
                    {/*                disabled={!detailLote.count > 0 ||  estadoExcelDetalles}*/}
                    {/*                onClick={descargarExcelDetalles}*/}
                    {/*        >*/}
                    {/*            Descargar excel de Detalles*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    conBusqueda
                                    titulo="Listado de reportes"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    width={'18%'}
                                    dataField="fecha_inicial"
                                    dataFormat={this.establecerPeriodo}
                                    dataSort>Periodo</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    width={'10%'}
                                    dataField="user_nickname"
                                    dataSort>Nickname</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataField="monto"
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Monto</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataField="nota_credito"
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Crédito</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataField="impuesto"
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Impuestos</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='center'
                                    width={'10%'}
                                    dataField="date_number"
                                    dataFormat={(cell) => (`#${cell}`)}
                                    dataSort>No. Detalle</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='center'
                                    dataField="fecha_expiracion"
                                    dataFormat={(cell) => moment(cell).format("DD/MM/YYYY")}
                                    dataSort>Vencimiento</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={activeFormatter({
                                        'ver': this.cargarDetallesReporte,
                                    })}>Acciones
                                </TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cargos;
