import React from 'react';
import {Field, reduxForm} from "redux-form";
import {renderField, renderNumber} from "../../../Utils/renderField";
import {validate, validators} from "validate-redux-form";
import Select from "react-select";
import {renderFieldCustom, SelectField} from "../../../Utils/renderField/renderField";


let DenunciasForm = props => {
    const inputStyle = {
        width: '90%',
    };
    const {handleSubmit, cancelForm, lista_cuentas, openProductos, valido, itemSeleccionado, setProductoSeleccionado} = props;
    return (
        <div>


            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Cuenta*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    isClearable
                                    backspaceRemovesValue={false}
                                    component={SelectField}
                                    name="cuenta_id"
                                    labelKey="label"
                                    valueKey="value"
                                    options={lista_cuentas}
                                    isSearchable
                                    placeholder="Seleccione la cuenta"
                                />
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="" className="m-0">Marca del Producto</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="marca" component={renderField} type="text"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="" className="m-0">Precio</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="precio"
                                       component={renderNumber}
                                       type="number"
                                       parse={(value) => parseFloat(value)}
                                       placeholder="Precio promedio"
                                       decimalScale={2}
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="" className="m-0">Título del Producto</label>
                            </div>
                            <div className="col-md-12  form-group d-flex">
                                <Field name="cadena_busqueda"
                                       component={renderField}
                                       type="text"
                                       placeholder="Producto a buscar en Mercado Libre México"
                                       divClassname="input-btn"
                                       className="form-control"/>
                                <button className="btn btn-success"
                                        style={{'width': '10%'}}
                                        title="Buscar producto"
                                        onClick={openProductos}>
                                    <i className="fa fa-ellipsis-h"/>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="" className="m-0">Porcentaje de tolerancia al precio (%)</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="porcentaje_margen_precio"
                                       component={renderNumber}
                                       type="number"
                                       parse={(value) => parseInt(value)}
                                       placeholder="Porcentaje de tolerancia con respecto al precio"
                                       className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end col-md-12">
                        <button type="reset"
                                onClick={cancelForm}
                                className="btn btn-danger mr-2"><i
                            className="fa fa-times"/> &nbsp; Cancelar
                        </button>
                        <button type="submit" className="btn btn-success"><i
                            className="fa fa-save"/> &nbsp; Guardar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
DenunciasForm = reduxForm({
    form: 'denuncias_form',
    initialValues: {
        id: '',
        marca: '',
        precio: '',
        cuenta_id: '',
        cadena_busqueda: '',
        porcentaje_margen_precio: '',
    },
    validate: data => {
        return validate(data, {
            'cuenta_id': validators.exists()('Campo requerido.'),
            'marca': validators.exists()('Campo requerido.'),
            'precio': validators.exists()('Campo requerido.'),
            'cadena_busqueda': validators.exists()('Campo requerido.'),
            'porcentaje_margen_precio': validators.exists()('Campo requerido.'),
        })
    }
})(DenunciasForm);

export default DenunciasForm;
