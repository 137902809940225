import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {validate, validators} from 'validate-redux-form';
import {renderField} from '../../Utils/renderField';
import "./login.css"
const LoginForm = (props) => {
    const {handleSubmit, pristine, reset, submitting} = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                {/*<label htmlFor="username">Usuario</label>*/}
                <Field name="username" label="Ingresa tu usuario" component={renderField} type="text" className="form-control"/>
                <span className="fa fa-user form-control-feedback text-muted"/>
            </div>
            <div className="form-group has-feedback">
                {/*<label htmlFor="password">Contraseña</label>*/}
                <Field
                    name="password"
                    label="Contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
                <span className="fa fa-lock form-control-feedback text-muted"/>

            </div>
            <div className="buttons-box">
                <button type="submit"
                        className="btn btn-block btn-login-color mt-lg"
                        style={{backgroundColor: '#222D32', color: 'white'}}>Iniciar Sesión</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
