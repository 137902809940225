import Swal from 'sweetalert2';
const url = 'producto/top_ten_productos';
import { api } from 'api';
import { handleActions } from 'redux-actions';


const LOADER_PREDICTIVO = 'LOADER_PREDICTIVO';
const SET_DATA_PREDICTIVO = 'SET_DATA_PREDICTIVO';
const SET_PAGE_PREDICTIVO = 'SET_PAGE_PREDICTIVO';
const SET_BUSCADOR_PREDICTIVO = 'SET_BUSCADOR_PREDICTIVO';
const SET_FILTRO_PREDICTIVO = 'SET_FILTRO_PREDICTIVO';

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({
        type: LOADER_PREDICTIVO,
        cargando: true,
    });
    const store = getStore();
    const search = store.predictivos.buscador;
    const filtro = store.predictivos.filtro_predictivo;
    let params = {
        page,
        search,
    };

    if (filtro) {
        params[''] = filtro;
    }
    api.get(`${url}`, params)
        .then((response) => {
            console.log(response);
            if (response) {
                dispatch({
                    type: SET_DATA_PREDICTIVO,
                    data: response,
                });
                dispatch({
                    type: SET_PAGE_PREDICTIVO,
                    page,
                });
            }
        })
        .catch((error) => {
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error',
            );
            dispatch({
                type: SET_DATA_PREDICTIVO,
                data: [],
            });
            dispatch({
                type: SET_PAGE_PREDICTIVO,
                page,
            });
        })
        .finally(() => {
            dispatch({
                type: LOADER_PREDICTIVO,
                cargando: false,
            });

        });
};

const search = (search) => (dispatch, getStore) => {
    dispatch({
        type: SET_BUSCADOR_PREDICTIVO,
        buscador: search,
    });
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro === 0) {
        filtro = null;
    }
    dispatch({
        type: SET_FILTRO_PREDICTIVO,
        filtro_predictivo: filtro,
    });
    dispatch(listar(1));
};

export const actions = {
    listar,
    search,
    filtro,
};
export const reducer = {
    [LOADER_PREDICTIVO]: (state, { cargando }) => {
        return {
            ...state,
            cargando,
        };
    },
    [SET_DATA_PREDICTIVO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE_PREDICTIVO]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },

    [SET_BUSCADOR_PREDICTIVO]: (state, { buscador }) => {
        return {
            ...state,
            buscador,
        };
    },
    [SET_FILTRO_PREDICTIVO]: (state, { filtro_predictivo }) => {
        return {
            ...state,
            filtro_predictivo,
        };
    },
};
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_predictivo: null,
    updateData: {},
};
export default handleActions(reducer, initialState);
