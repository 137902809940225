import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/denuncias/productos-denunciados/productos-denunciados';
import ProductosDenunciados from './productos-denunciados';

const ms2p = (state) => {
    return {
        ...state.productos_denunciados,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProductosDenunciados);
