import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {push} from 'react-router-redux'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";
import _ from "lodash";

const url = 'lote';

const LOADER_LOTE = 'LOADER_LOTE';
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const SET_BAJA_DATA = 'SET_BAJA_DATA';
const SET_DATA_LOTE = 'SET_DATA_LOTE';
const SET_PAGE_LOTE = 'SET_PAGE_LOTE';
const SET_PAGE_BAJAS = 'SET_PAGE_BAJAS';
const SET_FILTRO_LOTE = 'SET_FILTRO_LOTE';
const SET_PROVEEDORES = 'SET_PROVEEDORES';
const SET_BUSCADOR_LOTE = 'SET_BUSCADOR_LOTE';
const SET_PAGE_PRODUCTO = 'SET_PAGE_PRODUCTO';
const SET_DATA_PRODUCTOS = 'SET_DATA_PRODUCTOS';
const LOADER_DETAIL_LOTE = 'LOADER_DETAIL_LOTE';
const SET_PROVEEDOR_VALUE = 'SET_PROVEEDOR_VALUE';
const SET_UPDATE_DATA_LOTE = 'SET_UPDATE_DATA_LOTE';
const SET_DETAIL_LOTE_DATA = 'SET_DETAIL_LOTE_DATA';
const SET_PAGE_DETAIL_LOTE = 'SET_PAGE_DETAIL_LOTE';
const SET_BUSCADOR_PRODUCTO = 'SET_BUSCADOR_PRODUCTO';
const SET_FECHA_FINAL_VALUE = 'SET_FECHA_FINAL_VALUE';
const SET_UPDATE_DETAIL_LOTE = 'SET_UPDATE_DETAIL_LOTE';
const SET_ESTADO_EXCEL_BAJAS = 'SET_ESTADO_EXCEL_BAJAS';
const SET_FECHA_INICIAL_VALUE = 'SET_FECHA_INICIAL_VALUE';
const SET_ESTADO_EXCEL_DETALLES = 'SET_ESTADO_EXCEL_DETALLES';

const MALE = 1;
const FEMALE = 2;

const estadosDescarga = {
    PROCESANDO: 10,
    TERMINADO: 20,
    FALLIDO: 30
};


const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_LOTE, cargando: true});
    const store = getStore();
    const search = store.lotes.buscador;
    const filtroProveedor = store.lotes.proveedorValue;
    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {page, search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }

    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/lotes_por_proveedor`, params).catch((error) => {
        dispatch({type: LOADER_LOTE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_DATA_LOTE, data});
            dispatch({type: SET_PAGE_LOTE, page});
        }
        dispatch({type: LOADER_LOTE, cargando: false});
    })
};

const cambioProveedor = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_PROVEEDOR_VALUE, proveedorValue: param});
    dispatch(listar(1));
    dispatch(listarDetalles(1));
    dispatch(listBajasProducto(1))
};

const cambioFechaInicio = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_FECHA_INICIAL_VALUE, fechaInicial: param});
    dispatch(listar(1));
    dispatch(listarDetalles(1));
    dispatch(listBajasProducto(1));

};

const cambioFechaFinal = (param) => (dispatch, getStore) => {
    const store = getStore();
    dispatch({type: SET_FECHA_FINAL_VALUE, fechaFinal: param});
    dispatch(listar(1));
    dispatch(listarDetalles(1));
    dispatch(listBajasProducto(1));

};

const cargar_proveedores = () => (dispatch, getStore) => {
    const store = getStore();

    api.get(`proveedores`).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            let proveedores = [];
            data.results.forEach((item_proveedor) => {
                let new_proveedor = {'label': item_proveedor.nombre_proveedor, 'value': item_proveedor.id};
                proveedores.push(new_proveedor);
            });
            dispatch({type: SET_PROVEEDORES, lista_proveedores: proveedores});
            // dispatch({type: SET_PAGE_LOTE, page});
        }
        // dispatch({type: LOADER_LOTE, cargando: false});
    });
};

const listarProductos = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_LOTE, cargando: true});
    const store = getStore();
    const search = store.lotes.buscadorProducto;
    let params = {page, search};

    api.get(`producto/`, params).catch((error) => {
        dispatch({type: LOADER_LOTE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_DATA_PRODUCTOS, dataProductos: data});
            dispatch({type: SET_PAGE_PRODUCTO, pageProductos: page});
        }
        dispatch({type: LOADER_LOTE, cargando: false});
    })
};

const detail = (pageDetail = 1, id) => (dispatch, getState) => {
    dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    let params = {pageDetail, id};
    api.get(`${url}/detalles_de_lote/`, params).catch((error) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
        if (error.statusCode === 404) {
            dispatch(push(`${url}/listado/`))
        }
    }).then((data) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: false});
        if (data) {
            dispatch({type: SET_DETAIL_LOTE_DATA, detailLote: data});
            dispatch({type: SET_PAGE_DETAIL_LOTE, pageDetail});

        }
    })
};

const listarDetalles = (pageDetail = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    const store = getStore();
    const filtroProveedor = store.lotes.proveedorValue;
    const search = store.lotes.buscador;
    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {pageDetail, search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/detalles_de_lote`, params).catch((error) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
        if (error.statusCode === 404) {
            // dispatch(push(`${url}/listado/`))
        }
    }).then((data) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: false});
        if (data) {
            dispatch({type: SET_DETAIL_LOTE_DATA, detailLote: data});
            dispatch({type: SET_PAGE_DETAIL_LOTE, pageDetail});

        }
    })
};

const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('patients', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
};

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.carga_masiva.values;
    dispatch({type: LOADER_LOTE, cargando: true});
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_LOTE, cargando: false});
        Swal('Éxito', 'Se ha creado el paciente.', 'success')
            .then(() => {
                dispatch(push(`${url}/listado/`))
            })
    }).catch((error) => {
        dispatch({type: LOADER_LOTE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_LOTE, cargando: false})
    });
};

const updateRow = (value, row) => (dispatch, getStore) => {
    const {detailLote, detailLote: {results}} = _.cloneDeep(getStore().lotes);
    const encontrado = _.find(results, {id: row.id});
    encontrado.stock = value;
    dispatch({type: SET_DETAIL_LOTE_DATA, detailLote: {...detailLote, ...results}});
};

const updateDetalle = (id, stock) => (dispatch, getStore) => {
    const data = {stock};

    api.put(`/detalle_lotes/${id}`, data)
        .then((data) => {
            dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
            if (data) {
                Swal(
                    'Éxito',
                    'Stock actualizado con éxito.',
                    'success'
                )
            }
        })
        .catch((error) => {
            dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            );
            // if (error.statusCode === 404) {
            //     dispatch(push('/patients/create'));
            // }
        })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_LOTE, buscador: search});
    dispatch(listar(1));
    dispatch(listarDetalles(1));
    dispatch(listBajasProducto(1));


};

const searchProducto = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PRODUCTO, buscadorProducto: search});
    dispatch(listBajasProducto(1));

};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro === 0) {
        filtro = null
    }
    dispatch({type: SET_FILTRO_LOTE, filtro_patients: filtro});
    dispatch(listar(1));
};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});
    // if (!estado) {
    //     dispatch({type: SET_PAGE_DETAIL_LOTE, pageDetail: 1})
    // }
};

const listBajas = (page = 1, id) => (dispatch, getState) => {
    dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    let params = {page, id};
    api.get(`${url}/bajas_de_detalle`, params).catch((error) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
        if (error.statusCode === 404) {
            dispatch(push(`${url}/listado/`))
        }
    }).then((data) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: false});
        if (data) {
            dispatch({type: SET_BAJA_DATA, bajaDetail: data});
            dispatch({type: SET_PAGE_BAJAS, pageBajas: page});

        }
    })
};

// El async retorna una promesa
const resetBajasPage = () => async (dispatch, getState) => {
    dispatch({type: SET_PAGE_BAJAS, pageBajas: 1});
};

const listBajasProducto = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    const store = getStore();
    const search = store.lotes.buscador;
    const filtroProveedor = store.lotes.proveedorValue;

    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {page, search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/bajas_de_detalle`, params).catch((error) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargando: false});
        if (error.statusCode === 404) {
            dispatch(push(`${url}/listado/`))
        }
    }).then((data) => {
        dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: false});
        if (data) {
            dispatch({type: SET_BAJA_DATA, bajaDetail: data});
            dispatch({type: SET_PAGE_BAJAS, pageBajas: page});

        }
    })

};

const descargarArchivo = (context) => (dispatch, getStore) => {
    console.log('descargando archivo');
    console.log(context);
    window.location.replace(context.url);
    // let elem = document.createElement('a');
    // elem.href = context.url;
    // elem.download = context.name;
    // elem.target = 'hiddenIframe';
    // elem.click();
    dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false});
    dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});


};

const esperarDescarga = (idArchivo, nombreArchivo, tipoExcel) => (dispatch, getStore) => {
    let intervalPromise;
    intervalPromise = setInterval(() => {
        let params = {id: idArchivo};
        api.get(`${url}/estado_descarga`, params).catch((error) => {
            if (error.statusCode === 404) {
                let msg = 'Ocurrió un error al descargar el archivo. Por favor intenta más tarde';
                if (error.estado) {
                    msg = error.observaciones;
                }
                (tipoExcel === 'Detalles')
                    ?
                    dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
                    :
                    dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});
                // $interval.cancel(intervalPromise);
                clearInterval(intervalPromise);
                swal('Error al descargar!', msg, 'error');
            }
        }).then((data) => {
            if (data) {
                if (data.estado === estadosDescarga.PROCESANDO) {
                    (tipoExcel === 'Detalles')
                        ?
                        dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: true})
                        :
                        dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: true});
                } else if (data.estado === estadosDescarga.TERMINADO) {
                    clearInterval(intervalPromise);
                    // $interval.cancel(intervalPromise);
                    if (!nombreArchivo)
                        nombreArchivo = data.archivo ? data.archivo.split('/media/archivos/')[1] : 'Listado_detalles_lotes.xlsx';
                    let context = {
                        name: nombreArchivo,
                        url: data.archivo
                    };
                    (tipoExcel === 'Detalles')
                        ?
                        dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
                        :
                        dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});
                    dispatch(descargarArchivo(context))

                }
            }
        })
    }, 1000);
};

const descargarExcelDetalles = () => (dispatch, getStore) => {
    // dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    const store = getStore();
    const search = store.lotes.buscador;
    const filtroProveedor = store.lotes.proveedorValue;
    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/descargar_excel_detalles`, params).catch((error) => {
        if (error.statusCode === 404) {
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
        }
    }).then((data) => {
        if (data) {
            let nombreArchivo = '';
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: true});
            dispatch(esperarDescarga(data.id, nombreArchivo, 'Detalles'))
        }
    })
};

const descargarExcelBajas = () => (dispatch, getStore) => {
    // dispatch({type: LOADER_DETAIL_LOTE, cargandoDetalles: true});
    const store = getStore();
    const search = store.lotes.buscador;
    const filtroProveedor = store.lotes.proveedorValue;
    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/descargar_excel_bajas`, params).catch((error) => {
        if (error.statusCode === 404) {
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false})
        }
    }).then((data) => {
        if (data) {
            let nombreArchivo = '';
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: true});
            dispatch(esperarDescarga(data.id, nombreArchivo, 'Bajas'))
        }
    })
};


export const actions = {
    listar,
    detail,
    create,
    search,
    filtro,
    listBajas,
    updateRow,
    updateDetalle,
    listarDetalles,
    setToggleModal,
    resetBajasPage,
    searchProducto,
    cambioProveedor,
    listarProductos,
    createInitialize,
    cambioFechaFinal,
    listBajasProducto,
    cambioFechaInicio,
    cargar_proveedores,
    descargarExcelBajas,
    descargarExcelDetalles,
};
export const reducer = {
    [SET_PAGE_LOTE]: (state, {page}) => {
        return {...state, page}
    },
    [SET_DATA_LOTE]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_LOTE]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_BAJA_DATA]: (state, {bajaDetail}) => {
        return {...state, bajaDetail}
    },
    [SET_PAGE_BAJAS]: (state, {pageBajas}) => {
        return {...state, pageBajas}
    },
    [SET_BUSCADOR_LOTE]: (state, {buscador}) => {
        return {...state, buscador}
    },
    [SET_FILTRO_LOTE]: (state, {filtro_patients}) => {
        return {...state, filtro_patients}
    },
    [SET_UPDATE_DATA_LOTE]: (state, {updateData}) => {
        return {...state, updateData}
    },
    [SET_DETAIL_LOTE_DATA]: (state, {detailLote}) => {
        return {...state, detailLote}
    },
    [SET_PAGE_DETAIL_LOTE]: (state, {pageDetail}) => {
        return {...state, pageDetail}
    },
    [SET_PAGE_PRODUCTO]: (state, {pageProductos}) => {
        return {...state, pageProductos}
    },
    [SET_DATA_PRODUCTOS]: (state, {dataProductos}) => {
        return {...state, dataProductos}
    },
    [SET_PROVEEDORES]: (state, {lista_proveedores}) => {
        return {...state, lista_proveedores}
    },
    [SET_UPDATE_DETAIL_LOTE]: (state, {updateData}) => {
        return {...state, updateData}
    },
    [SET_PROVEEDOR_VALUE]: (state, {proveedorValue}) => {
        return {...state, proveedorValue}
    },
    [LOADER_DETAIL_LOTE]: (state, {cargandoDetalles}) => {
        return {...state, cargandoDetalles}
    },
    [SET_BUSCADOR_PRODUCTO]: (state, {buscadorProducto}) => {
        return {...state, buscadorProducto}
    },
    [SET_FECHA_INICIAL_VALUE]: (state, {fechaInicial}) => {
        return {...state, fechaInicial}
    },
    [SET_FECHA_FINAL_VALUE]: (state, {fechaFinal}) => {
        return {...state, fechaFinal}
    },
    [SET_ESTADO_EXCEL_DETALLES]: (state, {estadoExcelDetalles}) => {
        return {...state, estadoExcelDetalles}
    },
    [SET_ESTADO_EXCEL_BAJAS]: (state, {estadoExcelBajas}) => {
        return {...state, estadoExcelBajas}
    },
};

export const initialState = {
    fechaInicial: moment().startOf('month').format('YYYY-MM-DD'),
    fechaFinal: moment().format('YYYY-MM-DD'),
    page: 1,
    pageBajas: 1,
    buscador: '',
    pageDetail: 1,
    updateData: {},
    detailLote: {},
    bajaDetail: {},
    cargando: false,
    pageProductos: 1,
    dataProductos: {},
    toggleModal: false,
    proveedorValue: null,
    buscadorProducto: '',
    lista_proveedores: [],
    filtro_patients: null,
    cargandoDetalles: false,
    estadoExcelBajas: false,
    estadoExcelDetalles: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
};
export default handleActions(reducer, initialState)
