import Table from '../../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../../Utils/tableOptions';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {TableHeaderColumn} from "react-bootstrap-table";
import Modal from "react-responsive-modal";
import {activeFormatter} from "../../../Utils/Acciones/Acciones";
import DayPicker from "../../../Utils/DayPicker";
import {formatoMoneda} from "../../../Utils/renderField/renderReadField";
import Select from "react-select";
import UploadFileForm from "./archivos-form";

class ProductosDenunciados extends Component {

    const;

    constructor(props) {
        super(props);
        this.state = {
            idSubiendo: null,
            archivoAdjunto: null,
        };
    }

    openModal = (id, row) => {
        this.props.setToggleModal(true);
    };

    closeModal = () => {
        this.props.setToggleModal(false);
    };

    componentWillMount() {
        this.props.listar();
    };

    subirArchivo = (file, id) => {
        this.props.setArchivoAdjunto(true);
        this.setState({
            idSubiendo: id,
            archivoAdjunto: file,
        });
    };

    cancelarArchivo = () => {
        this.props.setArchivoAdjunto(false);
        this.setState({
            idSubiendo: null,
            archivoAdjunto: null,
        });
    };


    guardarArchivo = () => {
        const dataSubir = {
            id: this.state.idSubiendo
        };
        this.props.guardarArchivo(dataSubir, [{name: "adjunto", file: this.state.archivoAdjunto}]);
        this.setState({
            idSubiendo: null,
            archivoAdjunto: null,
        });
    };

    descargarZip = (id) => {
        this.props.descargarZip(id)
    };

    render() {
        const {
            listar,
            cambioStatus,
            descargarExcel,
            guardarArchivo,
            cambioFechaFinal,
            cambioFechaInicio,
            setArchivoAdjunto,
        } = this.props;


        const {
            page,
            data,
            cargando,
            fechaFinal,
            status_item,
            estadoExcel,
            fechaInicial,
            archivoCargado,
            archivoAdjunto,
            listado_estados,
        } = this.props;

        return (
            <div>
                <div className="title-view">
                    Productos Denunciados
                </div>
                <div className="content content-view">
                    <div className="py-4 row">
                        <div className="col-sm-3">
                            <label className="font-weight-bolder text-black">Fecha Inicial</label>
                            <DayPicker
                                onChange={cambioFechaInicio}
                                value={fechaInicial}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label className="font-weight-bolder text-black">Fecha Final</label>
                            <DayPicker
                                onChange={cambioFechaFinal}
                                value={fechaFinal}
                            />
                        </div>
                        <div className="col-sm-3">
                            <label className="font-weight-bolder text-black">Estado Publicación</label>
                            <Select
                                backspaceRemovesValue={false}
                                options={listado_estados}
                                value={status_item}
                                isClearable
                                onChange={cambioStatus}
                                placeholder="Seleccione un estado"
                            />
                        </div>
                    </div>
                    <div className="pb-3 d-flex justify-content-between">
                        <div className="col-sm-2 mx-0 px-0">
                            <button className="btn btn-success btn-block"
                                    disabled={!data.count > 0 || estadoExcel}
                                    onClick={descargarExcel}
                            >
                                <i className="fa fa-download"/>
                                &nbsp; Descargar listado
                            </button>
                        </div>
                    </div>
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    conBusqueda
                                    titulo="Productos Denunciados"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="id_item"
                                    dataSort>Item</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="item_status"
                                    dataSort>Status</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="titulo_producto"
                                    width={'30%'}
                                    // dataFormat={(cell) => formatoMoneda(cell.precio, '$MX ')}
                                    dataSort>Producto</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="precio"
                                    dataAlign="right"
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Precio</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataFormat={(cell, row) => `${row.id_seller} - ${row.nickname_seller}`}
                                    dataSort>Seller</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign="center"
                                    dataField="id_denuncia"
                                    isKey={true}
                                    dataSort>No. Denuncia</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign="center"
                                    dataField="cantidad_vendida"
                                    dataSort>Vendidos</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign="center"
                                    dataField="id"
                                    dataFormat={(cell, row) => {
                                        return (<UploadFileForm
                                            onSubmit={this.guardarArchivo}
                                            archivoAdjunto={archivoAdjunto}
                                            id={cell}
                                            row={row}
                                            descargarZip={() => this.descargarZip(cell)}
                                            loaded={archivoCargado}
                                            cancelForm={this.cancelarArchivo}
                                            idSubiendo={this.state.idSubiendo}
                                            setArchivoAdjunto={this.subirArchivo}/>)
                                    }
                                    }
                                    dataSort>Adjuntos</TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductosDenunciados;
