import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = {prueba: true};
    }

    render() {
        const {onSubmit} = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/"/>);
        }
        const year = new Date().getFullYear();
        return (
            <div>
                <div className="d-flex flex-column align-items-center pt-3 bienvenida">
                    {/*<h1 className="text-center">Bienvenido a CianStarter</h1>*/}
                    {/*<p>Página de login</p>*/}
                </div>
                <br/>
                <div className="row login-wrapper mt-xl">
                    <div className="panel panel-default panel-flat">
                        <div className="panel-heading text-center"
                             style={{padding: "20px 40px", backgroundColor: "#222D32"}}>
                            <a href="#">
                                <img className="center-block img-rounded img-login" src={require('../../../../../assets/img/logo.png')}
                                     alt="Image Preetail"/>
                            </a>
                        </div>
                        <div className="panel-body">
                            <p className="text-center pv">Inicia sesión para continuar.</p>
                            <LoginForm onSubmit={onSubmit}/>
                        </div>

                    </div>
                    {/*<div className="card card-login col-lg-3 col-md-4 col-11">*/}
                    {/*    <h5 className="text-center pv">INGRESAR</h5>*/}
                    {/*    /!*<span>¿No tienes cuenta?&nbsp;<Link to="/registro">Registrate aquí</Link></span>*!/*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    */}
                    {/*</div>*/}
                </div>
                <div className="p-lg text-center">
                    <span>&copy; </span>
                    <span>{year}</span>
                    <span> - </span>
                    <span>Preetail</span>
                </div>
            </div>
        );
    }
}

export default Login;
