import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const RenderNumber = ({value, decimalScale, className}) => {
    return (
        <NumberFormat className={className}
                      decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                      value={value} thousandSeparator={true} prefix={''} displayType={"text"}
        />
    )
};

export const RenderCurrency = ({value, className, simbolo='Q'}) => {
    return (
        <NumberFormat className={className}
                      decimalScale={2} fixedDecimalScale={true}
                      value={value} thousandSeparator={true} prefix={simbolo} displayType={"text"}
        />
    )
};

export const RenderDateTime = ({value, className}) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>{fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}</span>
        );
    }
    return (<span className={className}>{value}</span>);
};
export const cellTachado = (cell, row) => {
    if(row.anulado){
        return {textDecoration: "line-through", whiteSpace: 'normal'}
    }
    return {whiteSpace: 'normal'}
}
export const  dateFormatter = (cell)  => {
    if (!cell) {
        return "";
    }

    try
    {
        let date = moment(cell).format('DD/MM/YYYY')
        return date
    }
    catch(e){
        return cell;
    }

}
export const formatoMoneda = (cell, simbolo) =>{
    if ((typeof simbolo) === "object"){
        simbolo = simbolo.simbolo ? simbolo.simbolo : 'Q';
    }
    if(!cell) {
        return "-- --"
    }
    try{
        return (
            <NumberFormat
                decimalScale={2}
                fixedDecimalScale={true}
                value={cell !== null ? cell : 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={simbolo?simbolo: "Q"} />
        )
    }catch(e){
        return cell;
    }
}

export const ReadFields = {
    renderCurrency : RenderCurrency,
    renderNumber: RenderNumber,
    renderDateTime: RenderDateTime,
    dateFormatter: dateFormatter,
};
