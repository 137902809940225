import Swal from 'sweetalert2';
import { api } from 'api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'carga_masiva';
import moment from "moment";
import { departamentos, municipios } from '../../../utility/variables';

const LOADER_CARGA_MASIVA = 'LOADER_CARGA_MASIVA';
const SET_DATA_CARGA_MASIVA = 'SET_DATA_CARGA_MASIVA';
const SET_PAGE_CARGA_MASIVA = 'SET_PAGE_CARGA_MASIVA';
const SET_UPDATE_DATA_CARGA_MASIVA ='SET_UPDATE_DATA_CARGA_MASIVA';
const SET_BUSCADOR_CARGA_MASIVA = 'SET_BUSCADOR_CARGA_MASIVA';
const SET_FILTRO_CARGA_MASIVA = 'SET_FILTRO_CARGA_MASIVA';

const MALE = 1;
const FEMALE = 2;

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CARGA_MASIVA, cargando: true});
    const store = getStore();
    const search = store.carga_masiva.buscador;
    const filtro = store.carga_masiva.filtro_carga_masiva;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}/listado/`, params).catch((error) => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_CARGA_MASIVA, data});
            dispatch({type: SET_PAGE_CARGA_MASIVA, page});
        }
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false});
    })
};

const detail = id => (dispatch, getState) => {
    dispatch({type: LOADER_CARGA_MASIVA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push(`${url}/listado/`))
        }
    }).then((data) => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false});
        if(data){
            if(data.gender === MALE){
                data.gender = {value: MALE, label: 'Masculino'}
            } else {
                data.gender = {value: FEMALE, label: 'Femenino'}
            }
            for (let index = 0; index < departamentos.length; index++) {
                if(departamentos[index].value === data.departamento){
                    data.departamento = departamentos[index];
                }
            }
            for (let index = 0; index < municipios.length; index++) {
                if(municipios[index].value === data.municipio){
                    data.municipio = municipios[index];
                }
            }
            if(data.family_member_of){
                data.family_member_of.value = data.family_member_of.id;
                data.family_member_of.label = data.family_member_of.name;
            }
            if(data.referred_by){
                data.referred_by.value = data.referred_by.id;
                data.referred_by.label = data.referred_by.name;
            }
            dispatch({type: SET_UPDATE_DATA_CARGA_MASIVA, updateData: data});
            dispatch(initializeForm('patients', data))
        }
    })
}

const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('patients', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
};

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.carga_masiva.values;
    formData.gender = formData.gender.value;
    if(formData.departamento){
        formData.departamento = formData.departamento.value;
    }
    if(formData.municipio){
        formData.municipio = formData.municipio.value;
    }
    if(formData.family_member_of){
        formData.family_member_of = formData.family_member_of.value;
    }
    if(formData.referred_by){
        formData.referred_by = formData.referred_by.value;
    }
    dispatch({type: LOADER_CARGA_MASIVA, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false})
        Swal('Éxito', 'Se ha creado el paciente.', 'success')
            .then(() => {
                dispatch(push(`${url}/listado/`))
            })
    }).catch((error) => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false})
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_CARGA_MASIVA, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.patients.values);
    formData.gender = formData.gender.value;
    if(formData.departamento){
        formData.departamento = formData.departamento.value;
    }
    if(formData.municipio){
        formData.municipio = formData.municipio.value;
    }
    if(formData.family_member_of){
        formData.family_member_of = formData.family_member_of.value;
    }
    if(formData.referred_by){
        formData.referred_by = formData.referred_by.value;
    }
    dispatch({type: LOADER_CARGA_MASIVA, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
        .then((data) => {
            dispatch({type: LOADER_CARGA_MASIVA, cargando: false});
            if(data){
                Swal(
                    'Éxito',
                    'Datos editados correctamente.',
                    'success'
                ).then(() => {
                    dispatch(push('/patients'));
                })
            }
        })
        .catch((error) =>{
            dispatch({type: LOADER_PATIENTS, cargando: false});
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            )
            if(error.statusCode === 404){
                dispatch(push('/patients/create'));
            }
        })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PATIENTS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PATIENTS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PATIENTS, filtro_patients: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    createInitialize
};
export const reducer = {
    [LOADER_CARGA_MASIVA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_CARGA_MASIVA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_CARGA_MASIVA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_CARGA_MASIVA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_CARGA_MASIVA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CARGA_MASIVA]: (state, { filtro_patients }) => {
        return {...state, filtro_patients }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_patients: null,
    updateData: {},
};
export default handleActions(reducer, initialState)
