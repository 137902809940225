import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Registro } from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Examples';
import NotFound from './common/components/layout/NotFound/NotFound';
import PredictivoInventario from './common/components/Preetail/Predictivos/predictivo-inventario-container';
import CargaMasiva from "./common/components/Preetail/CargaMasiva/carga-masiva-container";
import Lotes from "./common/components/Preetail/Lotes/lotes-container";
import Cargos from "./common/components/Preetail/Cargos/cargos-container";

import '../assets/fonts/fonts.css';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import VerListadoCargaMasiva from "./common/components/Preetail/CargaMasiva/carga-masiva-container";
import CuentasExcluidas from "./common/components/Preetail/Denuncias/CuentasExcluidas/cuentas-excluidas-container";
import Cuentas from "./common/components/Preetail/Denuncias/Cuentas/cuentas-container";
import ProductosDenunciados from "./common/components/Preetail/Denuncias/ProductosDenunciados/productos-denunciados-container";
import Denuncias from "./common/components/Preetail/Denuncias/Denuncias/denuncias-container";
import DenunciasForm from "./common/components/Preetail/Denuncias/Denuncias/denuncias-crear-container";

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/registro" component={Registro}/>
                <ProtectedRoute exact path="/" component={Demo}/>
                <ProtectedRoute exact path="/page2" component={Examples}/>
                <ProtectedRoute exact path="/app/predictivo_inventario" component={PredictivoInventario}/>
                <ProtectedRoute exact path="/app/carga_masiva" component={CargaMasiva}/>
                <ProtectedRoute exact path="/app/carga_masiva/:id/:fecha/:titulo/:proveedor/ver_listado" component={VerListadoCargaMasiva}/>
                <ProtectedRoute exact path="/app/lotes" component={Lotes}/>
                <ProtectedRoute exact path="/app/cargos" component={Cargos}/>
                <ProtectedRoute exact path="/app/denuncias" component={Denuncias}/>
                <ProtectedRoute exact path="/app/denuncias/nueva" component={DenunciasForm}/>
                <ProtectedRoute exact path="/app/cuentas_excluidas" component={CuentasExcluidas}/>
                <ProtectedRoute exact path="/app/cuentas_denuncia" component={Cuentas}/>
                <ProtectedRoute exact path="/app/productos_denunciados" component={ProductosDenunciados}/>
                <Route component={NotFound}/>
            </Switch>
        </div>
        <NotificationContainer/>
    </div>
);
