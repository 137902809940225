import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/lotes/lotes';
import Lotes from './lotes';

const ms2p = (state) => {
    return {
        ...state.lotes,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Lotes);
