import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import ToolbarSimple from '../../Utils/Toolbar/ToolbarSimple';
import {activeFormatter} from '../../Utils/Acciones/Acciones';
import {TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment";
import Modal from "react-responsive-modal";
import {formatoMoneda} from "../../Utils/renderField/renderReadField";
import Select from "react-select";
import DayPicker from "../../Utils/DayPicker";
import "./productosModal.css"
import {Link} from "react-router-dom";

class productosModal extends Component {


    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar();
    };

    setImagePreview(row) {
        if (row.productos.length > 0) {
            if (row.productos[0].imagenes.length > 0) {
                return (<div className="img-thumbnail thumb48">
                    <img src={row.productos[0].imagenes[0].url} alt="preview" className="img-preview"/>
                </div>)
            } else {
                return (<div>
                        <span className="fa fa-picture-o"/>
                        <div>Sin imagen</div>
                    </div>
                )
            }
        } else {
            return (<div>
                    <span className="fa fa-picture-o"/>
                    <div>Sin imagen</div>
                </div>
            )
        }
    };

    setSKUDetails(row) {
        return (<div className="d-flex flex-column">
            <label htmlFor="" className="m-0 font-weight-bolder font-smaller">
                SKU
            </label>
            <label htmlFor="" className="m-0 font-smaller">
                {row.id}
            </label>
            <label htmlFor="" className="m-0 font-weight-bolder font-smaller">
                SKU Prov.
            </label>
            <label htmlFor="" className="m-0 font-smaller">
                {row.sku}
            </label>
            <label htmlFor="" className="m-0 font-weight-bolder font-smaller">
                Barcode
            </label>
            <label htmlFor="" className="m-0 font-smaller">
                {row.barcode}
            </label>
        </div>)
    };


    seleccionarProducto = (cell, row) => {
        return (
            <button className="btn btn-success" onClick={() =>this.props.setProductoSeleccionado(row)}>
                <span className="fa fa-plus"/>
            </button>
        )
    };


    render() {
        const {
            listar,
        } = this.props;


        const {
            page,
            buscador,
            cargando,
            data,
        } = this.props;

        return (
            <div>
                <div className="content content-view">
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    conBusqueda
                                    titulo="Listado de reportes"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey={true}
                                    dataFormat={activeFormatter({
                                        'adicional': this.seleccionarProducto,
                                    })}>Agregar
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="id"
                                    dataFormat={(cell, row) => this.setImagePreview(row)}
                                    dataSort>Imagen</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="id"
                                    dataFormat={(cell, row) => this.setSKUDetails(row)}
                                    dataSort>ID's</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="titulo"
                                    dataSort>Titulo</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="marca"
                                    dataSort>Marca</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataField="precio"
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Precio</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="user_nickname"
                                    dataSort>Proveedor</TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default productosModal;
