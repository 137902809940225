import Table from '../../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../../Utils/tableOptions';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {activeFormatter} from '../../../Utils/Acciones/Acciones';
import {TableHeaderColumn} from "react-bootstrap-table";
import moment from "moment";
import Modal from "react-responsive-modal";
import Select from "react-select";
import {formatoMoneda} from "../../../Utils/renderField/renderReadField";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import DayPicker from "../../../Utils/DayPicker";
import {Link} from "react-router-dom";


class Denuncias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            id: undefined,
            mostrarBajas: 'ver',
            loteInfo: undefined,
            tipoModal: 'detalle', //puede ser detalle para que la modal muestre los detalles o baja para las bajas
            detalleInfo: undefined,
            editandoDetalle: false,
            idDetalleVer: undefined,
            stockValueEdit: undefined,
            mostrarEdiciones: 'editar',
            idDetalleEditando: undefined,
        }
        ;
    }

    openModal = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            id,
            loteInfo: row,
            tipoModal: 'detalle',
        });
        this.props.detail(1, id);
    };

    editarDetalle = (id, row) => {
        this.setState({
            idDetalleEditando: id,
            editandoDetalle: true,
            mostrarBajas: 'noMostrar',
            mostrarEdiciones: 'noMostrar',
        });
    };

    closeModal = () => {
        this.props.setToggleModal(false);
        this.props.resetBajasPage();

    };

    cargarList = (tabIndex) => {
        this.setState({
            tabIndex,
        }, () => {
            if (tabIndex !== undefined) {
                switch (tabIndex) {
                    case 0: {
                        this.props.cargar_proveedores();
                        this.props.listarDetalles(this.props.pageDetail);
                    }
                        break;
                    case 1: {
                        this.props.cargar_proveedores();
                        this.props.listar(this.props.page);
                    }
                        break;
                    case 2: {
                        this.props.listBajasProducto(this.props.pageBajas);
                    }
                        break;
                    default:
                        this.props.cargar_proveedores();
                        this.props.listar();
                }

            }
        });
    };

    cargarBajas = (id, row) => {
        this.setState({
            idDetalleVer: id,
            detalleInfo: row,
            tipoModal: 'baja',
        }, () => {
            this.props.resetBajasPage().then(() => {
                this.props.listBajas(this.props.pageBajas, id)
            });
        });
    };

    cargarBajasDetalles = (id, row) => {
        this.props.setToggleModal(true);
        this.setState({
            loteInfo: undefined,
            idDetalleVer: id,
            detalleInfo: row,
            tipoModal: 'baja',
        }, () => {
            this.props.resetBajasPage().then(() => {
                this.props.listBajas(this.props.pageBajas, id)

            });
        });
    };

    habilitarGuardarDetalle = (id, row) => {
        return (
            <button className="btn btn-success" hidden={this.state.idDetalleEditando !== row.id}
                    onClick={(e) => {
                        e.preventDefault();
                        this.guardarDetalle(id, row)
                    }}>
                <span className="fa fa-save"/> Guardar
            </button>
        )
    };

    guardarDetalle = (id, row) => {
        this.setState({
            mostrarBajas: 'ver',
            editandoDetalle: false,
            mostrarEdiciones: 'editar',
            idDetalleEditando: undefined,
        }, () => this.props.updateDetalle(id, this.state.stockValueEdit));

    };


    backDetalles = () => {
        this.setState({
            tipoModal: 'detalle',
        });

    };

    componentWillMount() {
        this.props.listar()
    };


    inputStock = (cell, row) => {
        return (
            <input
                min={0}
                value={cell}
                type="number"
                placeholder="Stock"
                className="stock-input"
                disabled={this.state.idDetalleEditando !== row.id}
                onChange={(e) => {
                    this.setState({stockValueEdit: e.currentTarget.value});
                    this.props.updateRow(e.currentTarget.value, row)
                }}
            />
        )
    };

    calcularCosto = (cell, row) => {
        if (cell !== undefined) {
            let costo = cell.detalle_orden.pvp;
            let descuento = costo * (cell.detalle_orden.descuento / 100);
            let costo_final = costo - descuento;
            return formatoMoneda(costo_final, '$MX ');

        }
        {
            return 0
        }
    };

    calcularCostoDetalle = (cell, row) => {
        if (cell !== undefined) {
            let costo = cell.pvp;
            let descuento = costo * (cell.descuento / 100);
            let costo_final = costo - descuento;
            return formatoMoneda(costo_final, '$MX ');

        }
        {
            return 0
        }
    };

    render() {
        const {
            listar,
            editar,
            setEdicion,
            deleteDenuncia,
        } = this.props;


        const {
            page,
            data,
            cargando,
        } = this.props;

        return (
            <div>
                {this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal}>
                        <div className="modal-header">
                            <h5>Nueva Cuenta</h5>
                        </div>
                        <div className="modal-detail">
                        </div>
                        {/*<div className="modal-footer">*/}
                        {/*    <button className="btn btn-danger"> <i className="fa fa-times"/> &nbsp; Cancelar</button>*/}
                        {/*    <button className="btn btn-success"> <i className="fa fa-save"/> &nbsp; Guardar</button>*/}
                        {/*</div>*/}
                    </Modal>
                )}
                <div className="title-view">
                    Denuncias
                </div>
                <div className="content content-view">
                    <div className="pb-3 d-flex justify-content-between">
                        <div className="col-sm-2 mx-0 px-0">
                            <Link to={`/app/denuncias/nueva`} onClick={() => setEdicion(false)}>
                                <button className="btn btn-success" >
                                    <span className="fa fa-plus"/>&nbsp; Nueva Denuncia</button>
                            </Link>
                        </div>
                    </div>
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    conBusqueda
                                    titulo="DENUNCIAS"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="cadena_busqueda"
                                    width={'30%'}
                                    dataSort>Producto</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="marca"
                                    width={'10%'}
                                    dataSort>Marca</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="precio"
                                    dataAlign="right"
                                    width={'10%'}
                                    dataFormat={(cell) => formatoMoneda(cell, '$MX ')}
                                    dataSort>Precio</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="porcentaje_margen_precio"
                                    dataAlign="center"
                                    width={'20%'}
                                    dataFormat={(cell) => <span>{cell} %</span>}
                                    dataSort>Porcentaje de Tolerancia</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="cuenta"
                                    dataAlign="center"
                                    width={'10%'}
                                    dataFormat={(cell) => <span>{cell.nickname}</span>}
                                    dataSort>Cuenta</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={activeFormatter({
                                        eliminar: deleteDenuncia,
                                        editar: editar
                                    })}
                                >Acciones
                                </TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Denuncias;
