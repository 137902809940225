import {createAction, handleActions} from 'redux-actions';
import _ from "lodash";

import {push} from "react-router-redux";
import {NotificationManager} from "react-notifications";
import {api} from "api";

const SUBMIT = 'SUBMIT';
const LOADER = 'SIDEBAR_LOADER';
const RESULTS = 'RESULTS';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------
export const getMenuItems = () => (dispatch) => {
    api.get('/var_menu/var_menu_list').then((response) => {
        dispatch({type: RESULTS, results: response.results})
    }).catch((error) => {
        console.log("error");
        console.log(error);
        // NotificationManager.error('No se ha podido obtener el listado de items', 'ERROR', 0);
    }).finally(() => {
    });
};

export const toggle = (id) => (dispatch, getStore) => {
    const {sidebar: {results}} = _.cloneDeep(getStore());
    const item = _.find(results, {id});
    results.forEach((menuItem) => {
        menuItem === item ?
            menuItem.abierto = !menuItem.abierto
            :
            menuItem.abierto = false;
    });
    dispatch({type: RESULTS, results});
};

export const actions = {
    getMenuItems,
    toggle,
};

export const reducers = {
    [RESULTS]: (state, {results}) => {
        return {
            ...state,
            results,
        };
    },
};

export const initialState = {
    loader: false,
    results: []
};

export default handleActions(reducers, initialState);
