import Table from '../../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../../Utils/tableOptions';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import {TableHeaderColumn} from "react-bootstrap-table";
import Modal from "react-responsive-modal";
import CuentasForm from "./cuentas-form";
import {activeFormatter} from "../../../Utils/Acciones/Acciones";

class Cuentas extends Component {

    cuentas_excluidas = {};

    constructor(props) {
        super(props);
        this.state = {};
    }

    openModal = (id, row) => {
        this.props.setToggleModal(true);
    };

    closeModal = () => {
        this.props.setToggleModal(false);
    };

    componentWillMount() {
        this.props.listar();
    };


    render() {
        const {
            listar,
            create,
            editar,
            guardar,
            destroy,
        } = this.props;


        const {
            page,
            data,
            cargando,
        } = this.props;

        return (
            <div>
                {this.props.toggleModal && (
                    <Modal open={this.props.toggleModal} onClose={this.closeModal}>
                        <div className="modal-header">
                            <h5>Nueva Cuenta</h5>
                        </div>
                        <div className="modal-detail">
                            <CuentasForm onSubmit={guardar} cancelForm={this.closeModal}/>
                        </div>
                        {/*<div className="modal-footer">*/}
                        {/*    <button className="btn btn-danger"> <i className="fa fa-times"/> &nbsp; Cancelar</button>*/}
                        {/*    <button className="btn btn-success"> <i className="fa fa-save"/> &nbsp; Guardar</button>*/}
                        {/*</div>*/}
                    </Modal>
                )}
                <div className="title-view">
                    Cuentas
                </div>
                <div className="content content-view">
                    {/*<div className="py-4 d-flex justify-content-between">*/}
                    {/*    /!*<div className="col-sm-3 mx-0 px-0">*!/*/}
                    {/*    /!*    <label className="font-weight-bolder text-black">Proveedor</label>*!/*/}
                    {/*    /!*    <Select*!/*/}
                    {/*    /!*        backspaceRemovesValue={false}*!/*/}
                    {/*    /!*        options={lista_proveedores}*!/*/}
                    {/*    /!*        value={proveedorValue}*!/*/}
                    {/*    /!*        isClearable*!/*/}
                    {/*    /!*        onChange={cambioProveedor}*!/*/}
                    {/*    /!*        placeholder="Seleccione un proveedor"*!/*/}
                    {/*    /!*    />*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    /!*<div className="col-sm-3 mx-0 px-0">*!/*/}
                    {/*    /!*    <label className="font-weight-bolder text-black">Fecha Inicial</label>*!/*/}
                    {/*    /!*    <DayPicker*!/*/}
                    {/*    /!*        onChange={cambioFechaInicio}*!/*/}
                    {/*    /!*        value={fechaInicial}*!/*/}
                    {/*    /!*    />*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    /!*<div className="col-sm-3 mx-0 px-0">*!/*/}
                    {/*    /!*    <label className="font-weight-bolder text-black">Fecha Final</label>*!/*/}
                    {/*    /!*    <DayPicker*!/*/}
                    {/*    /!*        onChange={cambioFechaFinal}*!/*/}
                    {/*    /!*        value={fechaFinal}*!/*/}
                    {/*    /!*    />*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*</div>*/}
                    <div className="pb-3 d-flex justify-content-between">
                        <div className="col-sm-2 mx-0 px-0">
                            <button className="btn btn-success btn-block"
                                // disabled={!detailLote.count > 0 || estadoExcelDetalles}
                                    onClick={this.openModal}
                            >
                                <i className="fa fa-plus"/>
                                &nbsp; Nueva cuenta
                            </button>
                        </div>
                    </div>
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    conBusqueda
                                    titulo="CUENTAS"
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="nickname"
                                    // dataFormat={(cell) => formatoMoneda(cell.precio, '$MX ')}
                                    dataSort>Nickname</TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    dataField="client_id"
                                    // dataFormat={(cell) => formatoMoneda(cell.precio, '$MX ')}
                                    dataSort>MeLi ID</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    dataFormat={activeFormatter({
                                        eliminar: destroy,
                                        editar: editar
                                    })}
                                >Acciones
                                </TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cuentas;
