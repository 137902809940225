import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";

import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter } from '../../Utils/Acciones/Acciones';
import { showNestedFields } from '../../Utils/MyOptions';
import ToolbarSimple from "../../Utils/Toolbar/ToolbarSimple";
import { formatoMoneda } from "../../Utils/renderField/renderReadField"; //Mi archivo (Ricky)

// Function to get acces to role field 'str'

function formatRole(cell) {
    if (cell) {
        return <span>{cell.role}</span>;
    } else {
        return <span></span>;
    }
}

class PredictivoInventario extends Component {

    componentWillMount() {
        this.props.listar();
    };

    render() {

        const {
            listar,
        } = this.props;

        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div>
                <div className="title-view">
                    Elasticidad Precio de la Demanda
                </div>
                <div className="content content-view">
                    <div className="m-auto row-fluid w-100">
                        <div className="table-decoration">
                            <div className="top-title-toolbar">
                                <ToolbarSimple
                                    titulo="Top 25 (Ultimos 90 días)"
                                    conBusqueda
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            <Table
                                onPageChange={listar}
                                data={data}
                                loading={cargando}
                                page={page}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    isKey={true}
                                    width={'40%'}
                                    dataFormat={(cell) => cell.titulo}
                                    dataField="producto"
                                    dataSort>Producto</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'10%'}
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataField="cantidad" dataSort>Cantidad</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'10%'}
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataFormat={(cell) => `${cell} %`}
                                    dataField="epd" dataSort>EPD</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'15%'}
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataFormat={(cell) => formatoMoneda(cell.precio_inicial, '$MX ')}
                                    dataField="comportamiento"
                                    dataSort>Precio Inicial</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'15%'}
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataFormat={(cell) => formatoMoneda(cell.precio_final, '$MX ')}
                                    dataField="comportamiento"
                                    dataSort>Precio Final</TableHeaderColumn>
                                <TableHeaderColumn
                                    width={'15%'}
                                    tdStyle={BreakLine}
                                    dataAlign='right'
                                    dataFormat={(cell) => formatoMoneda(cell.precio_actual, '$MX ')}
                                    dataField="comportamiento"
                                    dataSort>Precio Actual</TableHeaderColumn>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PredictivoInventario;
