import Swal from 'sweetalert2';
import {api} from 'api';
import {handleActions} from 'redux-actions'
import {push} from 'react-router-redux'
import {initialize as initializeForm} from 'redux-form'
import moment from "moment";
import _ from "lodash";

const url = 'cargos';
const urlDetalles = 'detalles_cargos';


const TOGGLE_MODAL = 'TOGGLE_MODAL';
const LOADER_CARGOS = 'LOADER_CARGOS';
const SET_DATA_CARGOS = 'SET_DATA_CARGOS';
const SET_PAGE_CARGOS = 'SET_PAGE_CARGOS';
const SET_SEARCH_CARGOS = 'SET_SEARCH_CARGOS';

const SET_PAGE_DETALLES = 'SET_PAGE_DETALLES';
const SET_DETALLES_CARGOS = 'SET_DETALLES_CARGOS';
const LOADER_DETALLES = 'LOADER_DETALLES';

const SET_ESTADO_EXCEL_BAJAS = 'SET_ESTADO_EXCEL_BAJAS';
const SET_ESTADO_EXCEL_DETALLES = 'SET_ESTADO_EXCEL_DETALLES';

const estadosDescarga = {
    PROCESANDO: 10,
    TERMINADO: 20,
    FALLIDO: 30
};

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_CARGOS, cargando: true});
    const store = getStore();
    const search = store.cargos.buscador;
    // const fecha_inicial = store.lotes.fechaInicial;
    // const fecha_final = store.lotes.fechaFinal;
    let params = {page, search};

    // if (fecha_inicial && fecha_final) {
    //     params['fecha_inicial'] = fecha_inicial;
    //     params['fecha_final'] = fecha_final;
    // }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_CARGOS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if (data) {
            dispatch({type: SET_DATA_CARGOS, data});
            dispatch({type: SET_PAGE_CARGOS, page});
        }
        dispatch({type: LOADER_CARGOS, cargando: false});
    })
};

const detail = (pageDetalles = 1, id) => (dispatch, getState) => {
    dispatch({type: LOADER_DETALLES, cargandoDetalles: true});
    let params = {page: pageDetalles, cargo_id: id};
    api.get(`${urlDetalles}`, params).catch((error) => {
        dispatch({type: LOADER_DETALLES, cargandoDetalles: false});
        if (error.statusCode === 404) {
            dispatch(push(`${url}`))
        }
    }).then((data) => {
        dispatch({type: LOADER_DETALLES, cargandoDetalles: false});
        if (data) {
            dispatch({type: SET_DETALLES_CARGOS, dataDetalles: data});
            dispatch({type: SET_PAGE_DETALLES, pageDetalles});
        }
    })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_SEARCH_CARGOS, buscador: search});
    dispatch(listar(1));
};

const setToggleModal = (estado) => (dispatch, getState) => {
    dispatch({type: TOGGLE_MODAL, toggleModal: estado});
};

// El async retorna una promesa
const resetDetallesPage = () => async (dispatch, getState) => {
    dispatch({type: SET_PAGE_DETALLES, pageDetalles: 1});
};

const descargarArchivo = (context) => (dispatch, getStore) => {
    window.location.replace(context.url);
    dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false});
    dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});
};

const esperarDescarga = (idArchivo, nombreArchivo, tipoExcel) => (dispatch, getStore) => {
    let intervalPromise;
    intervalPromise = setInterval(() => {
        let params = {id: idArchivo};
        api.get(`${url}/estado_descarga`, params).catch((error) => {
            if (error.statusCode === 404) {
                let msg = 'Ocurrió un error al descargar el archivo. Por favor intenta más tarde';
                if (error.estado) {
                    msg = error.observaciones;
                }
                (tipoExcel === 'Detalles')
                    ?
                    dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
                    :
                    dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});
                clearInterval(intervalPromise);
                swal('Error al descargar!', msg, 'error');
            }
        }).then((data) => {
            if (data) {
                if (data.estado === estadosDescarga.PROCESANDO) {
                    (tipoExcel === 'Detalles')
                        ?
                        dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: true})
                        :
                        dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: true});
                } else if (data.estado === estadosDescarga.TERMINADO) {
                    clearInterval(intervalPromise);
                    if (!nombreArchivo)
                        nombreArchivo = data.archivo ? data.archivo.split('/media/archivos/')[1] : 'Listado_detalles_lotes.xlsx';
                    let context = {
                        name: nombreArchivo,
                        url: data.archivo
                    };
                    (tipoExcel === 'Detalles')
                        ?
                        dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
                        :
                        dispatch({type: SET_ESTADO_EXCEL_BAJAS, estadoExcelBajas: false});
                    dispatch(descargarArchivo(context))
                }
            }
        })
    }, 1000);
};

const descargarExcelDetalles = () => (dispatch, getStore) => {
    const store = getStore();
    const search = store.lotes.buscador;
    const filtroProveedor = store.lotes.proveedorValue;
    const fecha_inicial = store.lotes.fechaInicial;
    const fecha_final = store.lotes.fechaFinal;
    let params = {search};

    if (filtroProveedor) {
        params['filtro_proveedor'] = filtroProveedor.value
    }
    if (fecha_inicial && fecha_final) {
        params['fecha_inicial'] = fecha_inicial;
        params['fecha_final'] = fecha_final;
    }
    api.get(`${url}/descargar_excel_detalles`, params).catch((error) => {
        if (error.statusCode === 404) {
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: false})
        }
    }).then((data) => {
        if (data) {
            let nombreArchivo = '';
            //SWAL
            dispatch({type: SET_ESTADO_EXCEL_DETALLES, estadoExcelDetalles: true});
            dispatch(esperarDescarga(data.id, nombreArchivo, 'Detalles'))
        }
    })
};

export const actions = {
    listar,
    detail,
    search,
    setToggleModal,
    resetDetallesPage,
    descargarExcelDetalles,
};
export const reducer = {
    [SET_PAGE_CARGOS]: (state, {page}) => {
        return {...state, page}
    },
    [SET_DATA_CARGOS]: (state, {data}) => {
        return {...state, data}
    },
    [LOADER_CARGOS]: (state, {cargando}) => {
        return {...state, cargando}
    },
    [TOGGLE_MODAL]: (state, {toggleModal}) => {
        return {...state, toggleModal}
    },
    [SET_SEARCH_CARGOS]: (state, {buscador}) => {
        return {...state, buscador}
    },
    [SET_PAGE_DETALLES]: (state, {pageDetalles}) => {
        return {...state, pageDetalles}
    },
    [SET_DETALLES_CARGOS]: (state, {dataDetalles}) => {
        return {...state, dataDetalles}
    },
    [LOADER_DETALLES]: (state, {cargandoDetalles}) => {
        return {...state, cargandoDetalles}
    },
    [SET_ESTADO_EXCEL_DETALLES]: (state, {estadoExcelDetalles}) => {
        return {...state, estadoExcelDetalles}
    },
};

export const initialState = {
    page: 1,
    buscador: '',
    pageDetalles: 1,
    cargando: false,
    cargandoDetalles: false,
    dataDetalles: {},
    toggleModal: false,
    estadoExcelDetalles: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
};
export default handleActions(reducer, initialState)
