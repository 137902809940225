import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import './Navbar.css'
import {getAlerts} from "../../../../redux/modules/navbar/navbar";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpenNotificaciones: false,
            dropdownOpenConcurrentes: false
        };
    }

    componentDidMount() {
        this.props.getAlerts();
    }

    toggleNotificaciones = () => {
        this.setState({dropdownOpenNotificaciones: !this.state.dropdownOpenNotificaciones});
    };

    toggleConcurrentes = () => {
        this.setState({dropdownOpenConcurrentes: !this.state.dropdownOpenConcurrentes});
    };

    render() {
        const {navToggle, logOut, resultAlerts} = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <div className="ml-3 input-group input-group-seamless"/>
                </div>
                <ul className="flex-row navbar-nav">
                    <Dropdown direction="left" isOpen={this.state.dropdownOpenNotificaciones}
                              toggle={this.toggleNotificaciones}>
                        <DropdownToggle color="dark" caret className="nav-item-dropdown border-0">
                            <span className="fa fa-bell"/>
                            <span className="badge badge-pill badge-danger"> {resultAlerts.total_alertas}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>
                                <span className="fa fa-exclamation-triangle"/> Notificaciones
                            </DropdownItem>
                            {
                                resultAlerts.alertas.map((alert_item) => (
                                    <DropdownItem key={alert_item.id}>
                                        <span className="fa fa-exclamation"/>{alert_item.nombre}
                                        <div className='text-muted'>{alert_item.tipo}</div>
                                    </DropdownItem>

                                ))
                            }
                        </DropdownMenu>
                    </Dropdown>
                </ul>
                <ul className="flex-row navbar-nav">
                    <Dropdown direction="left" isOpen={this.state.dropdownOpenConcurrentes}
                              toggle={this.toggleConcurrentes}>
                        <DropdownToggle color="dark" caret className="nav-item-dropdown border-0">
                            <span className="fa fa-clock-o"/>
                            <div className="badge badge-pill badge-success">7
                            </div>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header><span className="fa fa-clock-o"/>Concurrentes</DropdownItem>
                            <DropdownItem>
                                <span className="fa fa-ellipsis-h"/>
                                Notificacion de Orders /orders/206848585
                            </DropdownItem>
                            <DropdownItem>
                                <span className="fa fa-ellipsis-h"/>
                                Notificacion de Orders /orders/206848585
                            </DropdownItem>
                            <DropdownItem>
                                <span className="fa fa-ellipsis-h"/>
                                Notificacion de Orders /orders/206848585
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                </ul>
                <nav className="nav">
                    <a className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                       onClick={navToggle}>
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </nav>
        );
    }
}

export default Navbar;
